import { useStaticQuery, graphql } from 'gatsby';
import { ConnectLogoQuery } from '../typings/graphql';

export const useConnectLogoQuery = (): ConnectLogoQuery =>
  useStaticQuery<ConnectLogoQuery>(graphql`
    query ConnectLogo {
      file(relativePath: { eq: "ConnectLogo.png" }) {
        childImageSharp {
          fixed(width: 172, height: 69) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
    }
  `);

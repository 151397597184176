import React, { createContext, useContext } from 'react';
import useAuth, { UseAuthResults, GetUserFunction } from '../hooks/useAuth';
import { ApiUser } from '../services/user';
import Layout from './layout';
import { Grid } from '@material-ui/core';
import FormHeader from './formHeader';

export type userCanFunction = (permission: Permission) => boolean;

export enum Permission {
  AdministrateConsole = 'f5be5724-bdff-420b-8c16-07b3ca2bba6e',
}

export interface AuthDetails {
  getUser: GetUserFunction;
  apiUser: ApiUser | undefined;
  changeLocation: (newLocation: string) => void;
  loggingIn: boolean;
  loggedIn: boolean;
  logout: () => void;
  login: () => void;
  userCan: userCanFunction;
}

const AuthContext = createContext<AuthDetails>({
  getUser: async () => undefined,
  apiUser: undefined,
  changeLocation: () => undefined,
  loggingIn: false,
  loggedIn: false,
  logout: () => undefined,
  login: () => undefined,
  userCan: () => false,
});

interface PureAuthContextProviderProps {
  authDetails: UseAuthResults;
}

export const PureAuthContextProvider: React.FC<PureAuthContextProviderProps> = ({
  children,
  authDetails: {
    error,
    loggingIn,
    loggedIn,
    getUser,
    apiUser,
    changeLocation,
    logout,
    login,
    userCan,
  },
}) => {
  if (!!error) {
    return (
      <Layout>
        <Grid alignContent="flex-start" justify="space-between" container>
          <Grid container justify="space-between" direction="row">
            <FormHeader>{error.message}</FormHeader>
          </Grid>
        </Grid>
      </Layout>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        getUser,
        apiUser,
        changeLocation,
        loggingIn,
        loggedIn,
        logout,
        login,
        userCan,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthContextProvider: React.FC = ({ children }) => {
  const authDetails = useAuth();
  return (
    <PureAuthContextProvider authDetails={authDetails}>
      {children}
    </PureAuthContextProvider>
  );
};

export default AuthContextProvider;

export const useAuthContext = (): AuthDetails => useContext(AuthContext);

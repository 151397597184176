import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Medical: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 53.308 53.308" {...props}>
    <path d="M50.184,4.165H46.491A4.17,4.17,0,0,0,42.327,0a4.09,4.09,0,0,0-4.116,4.165H30.874A4.17,4.17,0,0,0,26.709,0a4.09,4.09,0,0,0-4.116,4.165H15.256A4.17,4.17,0,0,0,11.092,0,4.09,4.09,0,0,0,6.976,4.165H3.124A3.127,3.127,0,0,0,0,7.288v42.9a3.127,3.127,0,0,0,3.124,3.124H50.184a3.127,3.127,0,0,0,3.124-3.124V7.288A3.127,3.127,0,0,0,50.184,4.165Zm-9.892-.029a2.01,2.01,0,0,1,2.034-2.053,2.085,2.085,0,0,1,2.082,2.082H40.293C40.293,4.155,40.293,4.145,40.293,4.135Zm-15.618,0a2.01,2.01,0,0,1,2.034-2.053,2.085,2.085,0,0,1,2.082,2.082H24.676C24.676,4.155,24.676,4.145,24.675,4.135Zm-15.618,0a2.01,2.01,0,0,1,2.034-2.053,2.085,2.085,0,0,1,2.082,2.082H9.058C9.058,4.155,9.058,4.145,9.058,4.135Zm41.127,47.09H3.124a1.042,1.042,0,0,1-1.041-1.041V14.576H21.969a1.041,1.041,0,1,0,0-2.082H2.082V7.288A1.042,1.042,0,0,1,3.124,6.247H6.976V9.371a1.041,1.041,0,1,0,2.082,0V6.247H22.593V9.371a1.041,1.041,0,1,0,2.082,0V6.247H38.211V9.371a1.041,1.041,0,1,0,2.082,0V6.247h9.891a1.042,1.042,0,0,1,1.041,1.041v5.206H31.339a1.041,1.041,0,1,0,0,2.082H51.226V50.184A1.042,1.042,0,0,1,50.184,51.226Zm0,0" />
    <path
      d="M152.029,195.371H143.7v-8.329A1.041,1.041,0,0,0,142.659,186h-6.247a1.041,1.041,0,0,0-1.041,1.041v8.329h-8.329A1.041,1.041,0,0,0,126,196.412v6.247a1.041,1.041,0,0,0,1.041,1.041h8.329v8.329a1.041,1.041,0,0,0,1.041,1.041h6.247a1.041,1.041,0,0,0,1.041-1.041V203.7h8.329a1.041,1.041,0,0,0,1.041-1.041v-6.247A1.041,1.041,0,0,0,152.029,195.371Zm-1.041,6.247h-8.329a1.041,1.041,0,0,0-1.041,1.041v8.329h-4.165v-8.329a1.041,1.041,0,0,0-1.041-1.041h-8.329v-4.165h8.329a1.041,1.041,0,0,0,1.041-1.041v-8.329h4.165v8.329a1.041,1.041,0,0,0,1.041,1.041h8.329Zm0,0"
      transform="translate(-112.881 -166.634)"
    />
    <path
      d="M248.082,121.041A1.041,1.041,0,1,1,247.041,120,1.041,1.041,0,0,1,248.082,121.041Zm0,0"
      transform="translate(-220.387 -107.506)"
    />
  </SvgIcon>
);

export default Medical;

import React, { useState } from 'react';
import {
  InputLabel,
  makeStyles,
  FormControl,
  IconButton,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { TooltipProps } from './risk-tooltip';

const useStyles = makeStyles(theme => ({
  fieldContainer: {
    width: '100%',
    marginBottom: '1.7rem',
  },
  label: {
    marginBottom: '0.5rem',
    display: 'flex',
  },
  labelWithDescription: {
    marginBottom: '0.2rem',
    display: 'flex',
  },
  description: {
    marginTop: 0,
    marginBottom: '0.7rem',
  },
  tooltipContainer: {
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    height: '1.875rem',
    width: '1.875rem',
    display: 'inline-block',
    textAlign: 'center',
    marginTop: '-0.25rem',
    marginLeft: '0.5rem',
  },
  tooltipIcon: {
    height: '1.31rem',
    width: '1.31rem',
    padding: 0,
    paddingTop: '0.1875rem',
  },
}));

interface Props {
  label: string;
  id: string;
  description?: string;
  tooltip?: React.FC<TooltipProps>;
  disableAnimation?: boolean;
  shrink?: boolean;
}

const LabeledInput: React.FC<Props> = ({
  id,
  label,
  description,
  tooltip: Tooltip,
  disableAnimation = false,
  shrink = false,
  children,
}) => {
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const closeTooltip = (): void => setTooltipOpen(false);
  return (
    <FormControl className={classes.fieldContainer}>
      <InputLabel
        className={!!description ? classes.labelWithDescription : classes.label}
        htmlFor={id}
        disableAnimation={disableAnimation}
        shrink={shrink}
      >
        {label}
        {!!Tooltip && (
          <div className={classes.tooltipContainer}>
            <IconButton
              size="small"
              className={classes.tooltipIcon}
              onClick={(): void => setTooltipOpen(true)}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </div>
        )}
      </InputLabel>
      {!!description && <p className={classes.description}>{description}</p>}
      {!!Tooltip && <Tooltip open={tooltipOpen} close={closeTooltip} />}
      {children}
    </FormControl>
  );
};

export default LabeledInput;

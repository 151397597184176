import { useState, useEffect } from 'react';
import { Configuration } from '../services/lists';
import { getConfiguration } from '../services/api';
import useIsMounted from './useIsMounted';
import { useAuthContext } from '../components/authContext';

export interface UseConfigResults {
  config?: Configuration;
  gettingConfig: boolean;
  error?: Error;
}

const useConfig = (): UseConfigResults => {
  const isMounted = useIsMounted();
  const [config, setConfig] = useState<Configuration | undefined>(undefined);
  const [gettingConfig, setGettingConfig] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const { getUser } = useAuthContext();

  useEffect(() => {
    setGettingConfig(true);

    getConfiguration(getUser)
      .then(value => {
        if (isMounted.current) setConfig(value);
      })
      .catch(error => {
        if (isMounted.current) setError(error);
      })
      .finally(() => {
        if (isMounted.current) setGettingConfig(false);
      });
  }, [getUser, isMounted]);
  return {
    config,
    gettingConfig,
    error,
  };
};

export default useConfig;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const TransportIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 37.65 23.373" {...props}>
    <path d="M37.1,9.71,31.8,8.335,28.025,2.185A4.61,4.61,0,0,0,24.12,0H3.932A3.936,3.936,0,0,0,0,3.932V19.558a.735.735,0,0,0,.735.735h2.8a3.815,3.815,0,1,0,0-1.47H1.47V12.642H5.936a.735.735,0,1,0,0-1.47H1.47V9.706H20.388v9.113s0,0,0,0h-4.36a.735.735,0,1,0,0,1.47h10.6a3.814,3.814,0,0,0,7.486,0h2.8a.735.735,0,0,0,.735-.735V10.422A.735.735,0,0,0,37.1,9.71Zm-29.825,7.5A2.344,2.344,0,1,1,4.93,19.558,2.347,2.347,0,0,1,7.275,17.214ZM36.18,13.8H34.321a.2.2,0,0,1-.2-.2V10.457l2.059.534ZM26.771,2.954l3.239,5.28H21.859V1.47H24.12A3.13,3.13,0,0,1,26.771,2.954ZM11.665,8.235V1.47h8.724V8.235ZM3.932,1.47h6.263V8.235H1.47v-4.3A2.464,2.464,0,0,1,3.932,1.47ZM30.371,21.9a2.344,2.344,0,1,1,2.344-2.344A2.347,2.347,0,0,1,30.371,21.9Zm3.743-3.079a3.814,3.814,0,0,0-7.486,0H21.858s0,0,0,0V9.706h9.365l1.5.39a.729.729,0,0,0-.077.326V13.6a1.672,1.672,0,0,0,1.67,1.67H36.18v3.548Zm0,0" />
  </SvgIcon>
);

export default TransportIcon;

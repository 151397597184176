import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Referral: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 62.654 62.654" {...props}>
    <g transform="translate(38.667 23.987)">
      <path
        d="M318.071,196.377a1.223,1.223,0,1,0,.359.865A1.233,1.233,0,0,0,318.071,196.377Z"
        transform="translate(-315.982 -196.018)"
      />
    </g>
    <g transform="translate(21.044)">
      <path
        d="M212.048.691l-.042-.03a3.651,3.651,0,0,0-2.1-.661H175.641a3.648,3.648,0,0,0-1.985.585,1.23,1.23,0,0,0-.132.089,3.669,3.669,0,0,0-1.555,3V22.763a3.676,3.676,0,0,0,3.671,3.671H184.7a1.224,1.224,0,0,0,0-2.448h-7.856l10.044-11.736,5.173,3.733a1.224,1.224,0,0,0,1.431,0l5.18-3.727,10.038,11.728H196.2a1.224,1.224,0,0,0,0,2.448h13.707a3.676,3.676,0,0,0,3.671-3.671V3.671A3.67,3.67,0,0,0,212.048.691Zm-37.6,22.331a1.226,1.226,0,0,1-.028-.259V3.671a1.217,1.217,0,0,1,.059-.375l10.418,7.518Zm18.33-9.539L177.483,2.448h30.632Zm18.356,9.28a1.224,1.224,0,0,1-.028.259l-10.44-12.2,10.419-7.5a1.218,1.218,0,0,1,.05.343Z"
        transform="translate(-171.969 0)"
      />
    </g>
    <g transform="translate(52.486 53.49)">
      <path
        d="M431.249,437.118a1.221,1.221,0,0,0-.514.113h-.6a1.224,1.224,0,0,0,0,2.448h1.111a1.224,1.224,0,0,0,1.224-1.224v-.113A1.224,1.224,0,0,0,431.249,437.118Z"
        transform="translate(-428.914 -437.118)"
      />
    </g>
    <g transform="translate(53.597 47.371)">
      <path
        d="M439.217,387.114a1.224,1.224,0,0,0-1.224,1.224v1.224a1.224,1.224,0,0,0,2.448,0v-1.224A1.224,1.224,0,0,0,439.217,387.114Z"
        transform="translate(-437.993 -387.114)"
      />
    </g>
    <g transform="translate(46.367 53.603)">
      <path
        d="M381.357,438.039h-1.224a1.224,1.224,0,1,0,0,2.448h1.224a1.224,1.224,0,0,0,0-2.448Z"
        transform="translate(-378.909 -438.039)"
      />
    </g>
    <g transform="translate(53.597 29.014)">
      <path
        d="M439.217,237.1a1.224,1.224,0,0,0-1.224,1.224v1.224a1.224,1.224,0,1,0,2.448,0v-1.224A1.224,1.224,0,0,0,439.217,237.1Z"
        transform="translate(-437.993 -237.1)"
      />
    </g>
    <g transform="translate(53.597 35.133)">
      <path
        d="M439.217,287.1a1.224,1.224,0,0,0-1.224,1.224v1.224a1.224,1.224,0,1,0,2.448,0v-1.224A1.224,1.224,0,0,0,439.217,287.1Z"
        transform="translate(-437.993 -287.105)"
      />
    </g>
    <g transform="translate(53.597 41.252)">
      <path
        d="M439.217,337.109a1.224,1.224,0,0,0-1.224,1.224v1.224a1.224,1.224,0,1,0,2.448,0v-1.224A1.224,1.224,0,0,0,439.217,337.109Z"
        transform="translate(-437.993 -337.109)"
      />
    </g>
    <g transform="translate(6.603 6.609)">
      <path
        d="M56.407,54.005H55.183a1.224,1.224,0,1,0,0,2.448h1.224a1.224,1.224,0,1,0,0-2.448Z"
        transform="translate(-53.959 -54.005)"
      />
    </g>
    <g transform="translate(12.722 6.609)">
      <path
        d="M106.411,54.005h-1.224a1.224,1.224,0,1,0,0,2.448h1.224a1.224,1.224,0,0,0,0-2.448Z"
        transform="translate(-103.963 -54.005)"
      />
    </g>
    <g transform="translate(6.603 11.504)">
      <path
        d="M55.183,94.008a1.224,1.224,0,0,0-1.224,1.224v1.224a1.224,1.224,0,1,0,2.448,0V95.232A1.224,1.224,0,0,0,55.183,94.008Z"
        transform="translate(-53.959 -94.008)"
      />
    </g>
    <g transform="translate(6.603 17.623)">
      <path
        d="M55.183,144.013a1.224,1.224,0,0,0-1.224,1.224v1.224a1.224,1.224,0,1,0,2.448,0v-1.224A1.224,1.224,0,0,0,55.183,144.013Z"
        transform="translate(-53.959 -144.013)"
      />
    </g>
    <g transform="translate(11.446 49.245)">
      <path
        d="M95.621,402.785a1.223,1.223,0,1,0,.359.865A1.233,1.233,0,0,0,95.621,402.785Z"
        transform="translate(-93.532 -402.426)"
      />
    </g>
    <g transform="translate(0 23.173)">
      <path
        d="M38.093,217.091l-6.448-6.448a4.752,4.752,0,0,0-6.712,0l-.42.42a1.5,1.5,0,0,1-2.117,0l-4.612-4.612a1.5,1.5,0,0,1,0-2.117l.42-.42a4.752,4.752,0,0,0,0-6.712l-6.448-6.448a4.752,4.752,0,0,0-6.712,0L3.5,192.3a11.983,11.983,0,0,0,0,16.927l4.206,4.206A1.224,1.224,0,0,0,9.437,211.7l-4.206-4.206a9.532,9.532,0,0,1,0-13.466l1.543-1.543a2.3,2.3,0,0,1,3.251,0l6.448,6.448a2.3,2.3,0,0,1,0,3.251l-.42.42a3.992,3.992,0,0,0-.418.493L10.541,198a1.224,1.224,0,0,0-1.731,1.731l6.114,6.114a3.914,3.914,0,0,0,1.128,2.333l4.612,4.612a3.949,3.949,0,0,0,5.578,0l.42-.42a2.3,2.3,0,0,1,3.251,0l6.448,6.448a2.3,2.3,0,0,1,0,3.251l-.876.876-5.869-5.869a1.224,1.224,0,1,0-1.731,1.731l5.784,5.784a9.536,9.536,0,0,1-12.317-.979l-3.169-3.169a1.224,1.224,0,1,0-1.731,1.731l3.169,3.169a11.982,11.982,0,0,0,16.927,0l1.543-1.543a4.746,4.746,0,0,0,0-6.712Z"
        transform="translate(0 -189.365)"
      />
    </g>
  </SvgIcon>
);

export default Referral;

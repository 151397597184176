import React from 'react';
import { Grid, Typography, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    paddingTop: theme.spacing(4),
    flex: 1,
  },
  containerWithSubHeading: {
    paddingTop: theme.spacing(4),
    flex: 1,
    marginBottom: theme.spacing(1),
  },
  title: {
    textTransform: 'uppercase',
  },
  titleWithSubHeading: {
    textTransform: 'uppercase',
    marginBottom: 0,
  },
}));

const CenterHeader: React.FC<{ subHeading?: string }> = ({
  subHeading,
  children,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      wrap="nowrap"
      spacing={2}
      className={
        !!subHeading ? classes.containerWithSubHeading : classes.headerContainer
      }
    >
      <Typography
        variant="h6"
        component="h1"
        className={!!subHeading ? classes.titleWithSubHeading : classes.title}
      >
        {children}
      </Typography>
      {subHeading && <p>{subHeading}</p>}
    </Grid>
  );
};

export default CenterHeader;

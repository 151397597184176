import React from 'react';
import { AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

interface FooterProps {
  noContrast?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  layoutFooter: {
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 0,
    minHeight: theme.spacing(8),
    top: 'auto',
  },
}));

const Footer: React.FC<FooterProps> = ({ noContrast, children }) => {
  const classes = useStyles({ noContrast });
  return (
    <AppBar className={classes.layoutFooter} component="footer">
      {children}
    </AppBar>
  );
};

export default Footer;

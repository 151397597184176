import React from 'react';
import { PropsFor } from '@material-ui/system';
import { Avatar, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  numberBadge: {
    width: '1.5rem',
    height: '1.5rem',
    backgroundColor: '#59BBC8',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: theme.palette.getContrastText('#59BBC8'),
  },
}));

interface LittleNumberProps extends PropsFor<typeof Avatar> {
  value: number;
}

const LittleNumber: React.FC<LittleNumberProps> = ({
  value,
  className,
  ...props
}) => {
  const classes = useStyles();
  if (!value) {
    return <></>;
  }
  return (
    <Avatar className={`${classes.numberBadge} ${className}`} {...props}>
      {value}
    </Avatar>
  );
};

export default LittleNumber;

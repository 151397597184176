/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import AuthContextProvider from './src/components/authContext';
import ErrorBoundary from './src/components/errorBoundary';
import FormContextProvider from './src/components/formContext';
import InteractionsContextProvider from './src/components/interactionsContext';
import OnlineContextProvider from './src/components/onlineContext';
import TripsContextProvider from './src/components/tripsContext';

export function wrapRootElement({ element }) {
  return (
    <ErrorBoundary>
      <AuthContextProvider>
        <FormContextProvider>
          <TripsContextProvider>
            <InteractionsContextProvider>
              <OnlineContextProvider>{element}</OnlineContextProvider>
            </InteractionsContextProvider>
          </TripsContextProvider>
        </FormContextProvider>
      </AuthContextProvider>
    </ErrorBoundary>
  );
}

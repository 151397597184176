import { navigate } from 'gatsby';
import * as yup from 'yup';
import { HTML_DATE_INPUT_FORMAT } from '../components/dateTime';
import { Tally, tallyTotal } from '../components/tallyTracker';
import { GetUserFunction } from '../hooks/useAuth';
import { generateId } from '../services/entity';
import {
  FORM_TYPE_PAGE_URL,
  FormType,
  FormTypeValues,
} from '../services/lists';
import { newMoment } from '../utilities/moment';
import {
  getItem as getFromLocalStorage,
  setItem as saveToLocalStorage,
  removeItem as removeFromLocalStorage,
} from '../utilities/safeStorage';
import {
  booleanToString,
  getActivityById,
  postInteraction,
  putInteraction,
  stringToBoolean,
} from './api';
import {
  PeopleCount,
  transformPeopleCountToTally,
  transformTallyToPeopleCount,
} from './tally';

export const defaultSchema = {
  formType: yup.mixed(),
  timeOccurred: yup.mixed(),
  id: yup.string(),
  individualClients: yup.array(yup.object<IndividualClient>()),
  tally: yup
    .mixed()
    .test(
      'tally-is-not-empty',
      'The tally needs at least one person',
      (tally: Tally) => tallyTotal(tally) > 0
    ),
};

export const followUpSchema = {
  followUp: yup.string().required(),
  followUpActions: yup
    .array()
    .of(yup.string())
    .when(['followUp'], {
      is: followUp => followUp === 'Yes',
      then: yup
        .array()
        .of(yup.string())
        .required(),
    }),
  followUpTime: yup.string().when(['followUp'], {
    is: followUp => followUp === 'Yes',
    then: yup.string().required(),
  }),
};

export interface IndividualClient {
  apiId?: number;
  index: number;
  ageGroup?: string;
  gender?: string;
  name?: string;
  condition?: string[];
  community?: string;
  followUp?: string;
  followUpTime?: string;
  followUpActions?: string[];
  repeatCustomer?: string;
  caseNumber?: string;
  reasons?: string[];
}

export interface ApiIndividualClient {
  id?: number;
  ageGroup?: string;
  gender?: string;
  conditions?: string[];
  community?: string;
  followUp?: boolean;
  followUpTime?: string;
  followUpActions?: string[];
  repeatCustomer?: boolean;
  caseNumber?: string;
  reasons?: string[];
}

export class Interaction {
  constructor(public formType: FormType) {}
  apiId?: number;
  id: string = generateId();
  name?: string = '';
  timeOccurred: string = newMoment().format(HTML_DATE_INPUT_FORMAT);
  actions?: string[] = undefined;
  outcomes?: string[] = undefined;
  location?: string = undefined;
  referredBy?: string = undefined;
  tally?: Tally = undefined;
  condition?: string[] = undefined;
  /**
   * @deprecated levelOfRisk property is no longer being used in any forms
   */
  levelOfRisk?: string = undefined;
  community?: string = undefined;
  transportRequired?: string = undefined;
  pickupLocation?: string = undefined;
  dropOffLocation?: string = undefined;
  actionType?: string = undefined;
  subtype?: string = undefined;
  followUp?: string = undefined;
  followUpTime?: string = undefined;
  repeatCustomer?: string = undefined;
  followUpActions?: string[] = undefined;
  caseNumber?: string = '';
  reasons?: string[] = undefined;
  otherStops?: string[] = undefined;
  contactMethods?: string[] = undefined;
  clientAdvocacy?: string = undefined;
  hasGuardian?: string = undefined;
  individualClients: IndividualClient[] = [];
  comments?: string;
}

export interface ApiInteraction {
  id?: number;
  friendlyId?: string;
  type?: string;
  actions?: string[];
  outcomes?: string[];
  location?: string;
  interactionTime?: string;
  peopleCounts?: PeopleCount[];
  conditions?: string[];
  /**
   * @deprecated levelOfRisk property is no longer being used in any forms
   */
  levelOfRisk?: string;
  referredBy?: string;
  community?: string;
  transportRequired?: boolean;
  pickUpLocation?: string;
  dropoffLocation?: string;
  actionType?: string;
  subtype?: string;
  formType: FormType;
  followUp?: boolean;
  followUpTime?: string;
  followUpReason?: string;
  repeatCustomer?: boolean;
  followUpActions?: string[];
  caseNumber?: string;
  reasons?: string[];
  otherStops?: string[];
  contactMethods?: string[];
  clientAdvocacy?: string;
  hasGuardian?: boolean;
  clients: ApiIndividualClient[];
  comments?: string;
}

export const interactionToApiInteraction = (
  interaction: Interaction
): ApiInteraction => {
  const {
    id,
    apiId,
    name,
    timeOccurred,
    tally,
    actions,
    transportRequired,
    condition,
    followUp,
    repeatCustomer,
    hasGuardian,
    individualClients,
    ...rest
  } = interaction;

  const interactionTimeMoment = newMoment(timeOccurred);
  if (!interactionTimeMoment.isValid()) {
    throw new Error('Entered time is invalid');
  }

  const apiIndividualClients: ApiIndividualClient[] = [];

  for (const individualClient of individualClients) {
    const {
      condition,
      followUp,
      repeatCustomer,
      apiId,
      ...clientRest
    } = individualClient;
    apiIndividualClients.push({
      conditions: condition,
      followUp: stringToBoolean(followUp),
      repeatCustomer: stringToBoolean(repeatCustomer),
      id: apiId,
      ...clientRest,
    });
  }

  return {
    friendlyId: id,
    id: apiId,
    interactionTime: interactionTimeMoment.toISOString(),
    peopleCounts: tally ? transformTallyToPeopleCount(tally) : [],
    actions: actions && actions.length ? actions : undefined,
    transportRequired: stringToBoolean(transportRequired),
    followUp: stringToBoolean(followUp),
    repeatCustomer: stringToBoolean(repeatCustomer),
    hasGuardian: stringToBoolean(hasGuardian),
    conditions: condition,
    clients: apiIndividualClients,
    ...rest,
  } as ApiInteraction;
};

export const apiInteractionToInteraction = (
  apiInteraction: ApiInteraction
): Interaction => {
  const {
    id,
    friendlyId,
    type,
    actions,
    conditions,
    peopleCounts,
    transportRequired,
    interactionTime,
    repeatCustomer,
    hasGuardian,
    dropoffLocation,
    pickUpLocation,
    followUp,
    clients,
    ...rest
  } = apiInteraction;

  const localClients: IndividualClient[] = [];
  for (const apiClient of clients) {
    const {
      id,
      conditions,
      followUp,
      repeatCustomer,
      ...localClientRest
    } = apiClient;
    localClients.push({
      apiId: id,
      index: clients.indexOf(apiClient),
      condition: conditions,
      followUp: booleanToString(followUp),
      repeatCustomer: booleanToString(repeatCustomer),
      ...localClientRest,
    });
  }

  return {
    apiId: id,
    tally: transformPeopleCountToTally(peopleCounts || []),
    transportRequired: booleanToString(transportRequired),
    followUp: booleanToString(followUp),
    timeOccurred: newMoment(interactionTime).format(HTML_DATE_INPUT_FORMAT),
    repeatCustomer: booleanToString(repeatCustomer),
    hasGuardian: booleanToString(hasGuardian),
    id: friendlyId || '',
    actions: actions || [],
    condition: conditions || [],
    individualClients: localClients,
    dropOffLocation: dropoffLocation,
    pickupLocation: pickUpLocation,
    ...rest,
  };
};

export const completeInteraction = async (
  interaction: Interaction,
  getUser: GetUserFunction
): Promise<ApiInteraction> =>
  interaction.id
    ? putInteraction(getUser, interactionToApiInteraction(interaction))
    : postInteraction(getUser, interactionToApiInteraction(interaction));

export const getInteraction = async (
  id: number,
  getUser: GetUserFunction
): Promise<Interaction> =>
  apiInteractionToInteraction(await getActivityById(getUser, id));

/** New functions to generalise the concept of an Interaction */

const isBrowser = typeof window !== 'undefined';

export type InteractionCounts = Record<FormType, number>;
export const InitialInteractionCounts = (FormTypeValues.reduce((acc, type) => {
  return {
    ...acc,
    [type]: 0,
  };
}, {}) as any) as InteractionCounts;

export const navigateToForm = (type: FormType, id?: string): void => {
  const url = FORM_TYPE_PAGE_URL[type];
  const options = id ? { state: { id } } : undefined;
  navigate(url, options);
};

const mergeOldTripsValues = (): void => {
  // 2024: 'tripsContext' has been replaced by the more generalized 'interactionsContext'
  // for backwards-compatibility, FormType.Transport should check 2 keys in local storage: 'trips' (old key) & 'Transport' (new key)
  // This check can be removed after going-live
  const oldKey = 'trips';
  const newKey = FormType.Transport;

  const oldValues = isBrowser ? getFromLocalStorage(oldKey) ?? '[]' : '[]';
  if (oldValues === '[]') {
    return;
  }

  const newValues = isBrowser ? getFromLocalStorage(newKey) ?? '[]' : '[]';

  const oldTrips = JSON.parse(oldValues) as Interaction[];
  const newTransportInteractions = JSON.parse(newValues) as Interaction[];

  const interactions = newTransportInteractions.concat(oldTrips);
  saveToLocalStorage(newKey, JSON.stringify(interactions));
  removeFromLocalStorage(oldKey);
};

export const getLocalAll = (type: FormType): Interaction[] => {
  if (type === FormType.Transport) {
    mergeOldTripsValues();
  }

  const jsonInteractions = isBrowser ? getFromLocalStorage(type) ?? '[]' : '[]';
  return JSON.parse(jsonInteractions) as Interaction[];
};

export const getTotalLocalCounts = (): number => {
  return FormTypeValues.reduce(
    (acc, type) => acc + getLocalAll(type).length,
    0
  );
};

export const getLocalCounts = (): InteractionCounts => {
  return (FormTypeValues.reduce((acc, type) => {
    return {
      ...acc,
      [type]: getLocalAll(type).length,
    };
  }, {}) as any) as InteractionCounts;
};

export const removeLocal = (type: FormType, interaction: Interaction): void => {
  const savedInteractions = getLocalAll(type);
  const interactionToRemoveIndex = savedInteractions.findIndex(
    i => i.id === interaction.id
  );
  if (interactionToRemoveIndex > -1) {
    savedInteractions.splice(interactionToRemoveIndex, 1);
    saveToLocalStorage(type, JSON.stringify(savedInteractions));
  }
};

export const saveLocal = (
  type: FormType,
  interaction: Interaction,
  onSaveComplete: () => void
): void => {
  const savedInteractions = getLocalAll(type);

  const interactionToUpdateIndex = savedInteractions.findIndex(
    i => i.id === interaction.id
  );
  if (interactionToUpdateIndex > -1) {
    savedInteractions[interactionToUpdateIndex] = interaction;
  } else {
    savedInteractions.push(interaction);
  }

  saveToLocalStorage(type, JSON.stringify(savedInteractions));

  onSaveComplete();
};

export const submitToApi = async (
  interaction: Interaction,
  getUser: GetUserFunction,
  onSubmitComplete: () => void
): Promise<void> => {
  interaction.apiId
    ? await putInteraction(getUser, interactionToApiInteraction(interaction))
    : await postInteraction(getUser, interactionToApiInteraction(interaction));

  onSubmitComplete();
};

import { useRef, useEffect, MutableRefObject } from 'react';

const useIsMounted = (): MutableRefObject<boolean> => {
  // Here's how we'll keep track of our component's mounted state
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return (): void => {
      componentIsMounted.current = false;
    };
  }, []); // Using an empty dependency array ensures this only runs on unmount

  return componentIsMounted;
};

export default useIsMounted;

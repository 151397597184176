import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AdminConsole: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 256 256" {...props}>
    <path
      d="M252.6,248.6c-4.5,0.3-4-2.5-4-5.4c0-16.3,0.1-32.6,0-49c-0.1-17-7.9-29.1-23.5-36.1c-5.8-2.6-11.6-5-17.8-7.6
	c21.8-9.4,26.8-34.5,11.9-50.7c-5.9-6.5-7.6-15-7.7-23.7c0-7.2,0.2-14.3-0.3-21.5c-1.9-26.6-21.9-49-47.3-53.5
	c-27.8-4.9-53.7,8.8-65.2,34c-5,11-5.5,22.6-4.9,34.2c0.6,12.9-2.6,24.2-10.9,34.3c-6.4,7.9-7.5,17.3-4.6,26.9
	c3,9.7,9.8,16.2,19.7,20c-6.8,3-13.1,5.6-19.3,8.5c-14.7,6.8-22.3,18.4-22.6,34.6c-0.2,12.3,0,24.6,0,37c0,2.2-0.1,4.3,0,6.5
	c0.1,2.3,1.4,3.7,3.8,3.8c2.2,0.1,3.3-1.4,3.7-3.4c0.2-1.3,0.1-2.7,0.1-4c0-13.5,0-27,0-40.5c0-9.4,3.5-17.4,11.1-22.9
	c9.3-6.8,20.5-9.7,31.4-14.8c0,5.1,0,9.4,0,13.7c0,8.8,0,8.8-8.9,8.8c-9.9,0-14.2,4.3-13.3,14.1c1.6,17.4,3.4,34.8,5.5,52.1
	c0.5,3.8-0.5,4.4-4,4.4c-11.5-0.2-23-0.1-34.5-0.1c-15.3,0-30.6,0-46,0c-2.7,0-5.2,0.7-5,4.1c0.2,3,2.5,3.6,5.1,3.5c1,0,2,0,3,0
	c79.9,0,159.9,0,239.8,0c1.3,0,2.7,0,4,0c2.3-0.1,3.8-1.4,3.8-3.7C256,250,254.6,248.5,252.6,248.6z M216.3,108.9
	c7.3,9.3,4.6,22.9-4.7,30.3c-9,7.2-23.1,6.4-31-1.6c-1.9-1.9-3.1-4.2-4.1-6.8c-0.7-1.9-0.4-2.9,1.4-4.1c12.5-8.2,18-19.9,17.4-34.8
	c-0.1-2.3,0-4.7,0-7c0-4.8,0.1-9.7,0-14.5c-0.1-5.3,3.9-2.5,6.1-3.2c2.9-0.9,2.8,1.3,2.7,3.2C203,85,207.3,97.5,216.3,108.9z
	 M186.2,161.3c-11.4,3.2-23.1,4.4-34.2,4.7c-12.1-0.1-23.3-1.5-34.3-4.2c-1.5-0.4-4.2-0.1-3.6-3c0.5-2.8-2-6.4,2.6-8
	c9-3.2,16.2-8.4,19-18.1c0.4-1.5,1.3-1.4,2.6-1.2c9.4,1.4,18.9,1.4,28.3,0c1.6-0.2,2.2-0.3,2.8,1.5c2.7,8.8,9,14.3,17.5,16.9
	c4.5,1.4,4,4.4,4.1,7.7C191,161.2,188.2,160.7,186.2,161.3z M182.2,177.8c-21.3,0-42.6,0-63.8,0c-1.4,0-3.6,0.8-4-0.8
	c-0.6-2.3-0.4-5-0.1-7.4c0.2-1.3,1.7-0.5,2.5-0.3c24.6,5.9,49,5.3,73.9-1.2C191.4,177.8,191.4,177.8,182.2,177.8z M190.2,23.9
	c8.1,9,12.5,19.4,13.8,31.4c0.4,3.9-0.9,4.9-4.6,4.8c-8.7-0.2-16.8-2.1-24-7.1c-2.4-1.7-4.9-2.9-6.8,0.2c-1.7,2.8,0.4,4.6,2.5,6.1
	c4.3,3,8.9,5.2,14,6.4c2.2,0.6,2.9,1.4,2.9,3.7c-0.2,5,0,10-0.1,15c-0.1,5.7-8,15.4-13.3,16.9c-1.8,0.5-1.9-0.7-2.6-1.5
	c-4.7-4.5-18.1-5.6-23.4-1.9c-5.7,4-6.4,12.1-1.4,16.9c4.9,4.8,20.2,5.2,24-0.4c3.8-5.5,9.7-7.8,14.3-11.9c0.3-0.3,0.8-0.3,1.3-0.5
	C184.6,113,176,121.9,165,124c-8.1,1.6-16.3,1.6-24.3,0.1C126.9,121.7,117.2,110,117,96c-0.1-8.7,0.1-17.3-0.1-26
	c0-2.6,0.6-3.6,3.4-4.2c26.8-5.3,48.2-19.2,63.8-41.7C186.6,20.7,187.7,21.2,190.2,23.9z M166.8,106.9c0,5.2-4.9,2.7-7.5,3.5
	c-3.2-0.6-8,1.4-7.8-3.8c0.2-4.4,4.6-3.3,7.5-3.2C162,103.4,166.8,102,166.8,106.9z M101.2,55.7c1.5-26.6,24.2-48,54.5-48
	c5.8-0.1,14.3,2.2,22.3,6.6c2,1.1,2.6,2.1,1.1,4.2c-18.3,25.5-42.3,40.7-74.5,40.9C101.7,59.5,101.1,58.5,101.2,55.7z M89.4,135.5
	c-6.4-8.3-6.8-19.5-0.2-27.7c8.6-10.7,13-22.7,11.9-36.4c-0.3-3.5,0.8-4.4,4.3-4.5c3.5,0,4.1,1.3,3.9,4.3c-0.2,4.1-0.1,8.3-0.1,13.4
	c0.3,4.7-0.4,10.4,0.5,16c1.6,10.8,6.8,19.4,15.9,25.3c3.2,2.1,3.2,3.9,1.9,6.9C121.4,146.7,99.2,148.3,89.4,135.5z M216.9,192.4
	c-1.9,17.5-3.9,35-5.6,52.6c-0.3,2.9-1.2,3.5-3.9,3.5c-35.3-0.1-70.6-0.1-105.8,0c-2.9,0-4.1-0.7-4.4-3.8
	c-1.7-17.9-3.6-35.7-5.6-53.6c-0.5-4.6,1.6-5.9,5.7-5.9c19,0.1,37.9,0,56.9,0c18.8,0,37.6,0,56.4,0
	C216.6,185.2,217.5,186.3,216.9,192.4z M237.6,248.4c-5.2-0.1-10.3-0.2-15.5,0c-2.9,0.1-3.6-0.8-3.3-3.7c1.9-17.7,3.7-35.4,5.6-53.1
	c0.9-8.5-3.6-13.7-12.2-13.8c-4.5-0.1-10.4,1.4-13-0.8c-3-2.7-0.7-8.8-1-13.4c-0.1-2.6,0-5.3,0-8.8c9.7,4.3,18.9,7.8,27.5,12.2
	c9.6,4.9,14.8,13.5,15.1,24.2c0.4,18,0.1,36,0.2,53.9C241.1,248.1,240,248.5,237.6,248.4z"
    />
    <path
      d="M87,47C88.7,23.6,71.7,3,48.5,0.3C25.3-2.4,4.2,14,0.5,37.7C-3,59.9,13,81.8,35.9,86.3
	c7.3,1.4,14.6,0.3,20.7,0.8c6.7,0,12.2-0.1,17.7,0c3.2,0.1,6.2-0.2,7.3-3.7c1.2-3.7-1.3-5.7-4.1-6.9c-3.3-1.5-2.6-2.7-0.6-4.9
	C82.9,64.5,86.3,56.2,87,47z M69.1,68.7c-3,3.2-5.2,6-1.9,10.8c-10.4-0.2-20.3,0.9-30-0.6C18.6,76.1,6.1,58.5,8.1,38.8
	c1.8-17.7,18.4-31.9,36.7-31c15.5,0.7,26.5,8.6,32.1,22.8C82.5,44.6,79.7,57.7,69.1,68.7z"
    />
    <path
      d="M154.3,231c-7.9,0-14.2-6.3-14.2-14.2c0-7.9,6.2-14.2,14.2-14.2c8,0,14.2,6.2,14.2,14.2
	C168.5,224.8,162.2,231,154.3,231z"
    />
    <path
      d="M28.7,47.3c-2.6-0.3-3.8-1.6-3.7-4c0.1-2.2,1.4-3.3,3.5-3.4c2.4,0,3.7,1.3,3.7,3.6C32.3,46,30.8,47.1,28.7,47.3
	z"
    />
    <path
      d="M47.3,43.1c-0.1,2.7-1.3,4.1-3.6,4.1c-2.4,0-3.7-1.3-3.7-3.7c0-2.1,1.2-3.4,3.4-3.5
	C45.7,39.8,47,41.1,47.3,43.1z"
    />
    <path d="M62.3,43.3c0,2.4-1.2,3.8-3.6,3.9c-2.4,0-3.7-1.3-3.7-3.7c0-2.1,1.1-3.4,3.3-3.6C60.7,39.8,62,41,62.3,43.3z" />
  </SvgIcon>
);

export default AdminConsole;

import axios, { AxiosResponse } from 'axios';
import { GetUserFunction } from '../hooks/useAuth';
import { AuthedUser } from '../typings/types';
import {
  ApiActivityLog,
  ApiGetActivityLogResponse,
} from './activityLogs.types';
import { ApiInteraction } from './interactions';
import { Configuration } from './lists';
import { ApiUser } from './user';

const BASE_URL: string = process.env.GATSBY_API_URL || '';
axios.defaults.baseURL = BASE_URL;

export const stringToBoolean = (
  value: string | undefined
): boolean | undefined =>
  value === 'Yes' ? true : value === 'No' ? false : undefined;

export const booleanToString = (
  value: boolean | undefined
): string | undefined =>
  value === true ? 'Yes' : value === false ? 'No' : undefined;

interface Headers {
  'content-type': string;
  Authorization: string;
}

const buildHeaders = (authedUser: AuthedUser): Headers => ({
  'content-type': 'application/json',
  Authorization: `Bearer ${authedUser.idToken}`,
});

export const getUser = async (getMsalUser: GetUserFunction): Promise<ApiUser> =>
  getMsalUser()
    .then(user =>
      axios.get('/api/user', {
        headers: buildHeaders(user as AuthedUser),
      })
    )
    .then((response: AxiosResponse) =>
      Promise.resolve(response.data as ApiUser)
    );

export const getLocations = async (
  getUser: GetUserFunction
): Promise<string[]> =>
  getUser()
    .then(user =>
      axios.get('/api/config/locations', {
        headers: buildHeaders(user as AuthedUser),
      })
    )
    .then((response: AxiosResponse) =>
      Promise.resolve(response.data as string[])
    );

export const getConfiguration = async (
  getUser: GetUserFunction
): Promise<Configuration> =>
  getUser()
    .then(user =>
      axios.get('/api/config/user', {
        headers: buildHeaders(user as AuthedUser),
      })
    )
    .then((response: AxiosResponse) =>
      Promise.resolve(response.data as Configuration)
    );

export const getConfigurationByLocation = async (
  getUser: GetUserFunction,
  location: string
): Promise<Configuration> =>
  getUser().then(user =>
    axios
      .get(`/api/config/${location}`, {
        headers: buildHeaders(user as AuthedUser),
      })
      .then((response: AxiosResponse) => {
        if (!response.data.items.Home) {
          response.data.items.Home = { services: [] };
        }
        return Promise.resolve(response.data as Configuration);
      })
  );

export const updateLocationConfiguration = async (
  getUser: GetUserFunction,
  config: Configuration
): Promise<void> =>
  getUser().then(user =>
    axios.put(`/api/config/locations`, config, {
      headers: buildHeaders(user as AuthedUser),
    })
  );

export const cloneLocationConfiguration = async (
  getUser: GetUserFunction,
  request: Configuration
): Promise<void> =>
  getUser().then(user =>
    axios.post(`/api/config/locations/clone`, request, {
      headers: buildHeaders(user as AuthedUser),
    })
  );

export const setUserLocation = async (
  getUser: GetUserFunction,
  location: string
): Promise<ApiUser> =>
  getUser()
    .then(user =>
      axios.post(
        '/api/user',
        { location },
        {
          headers: buildHeaders(user as AuthedUser),
        }
      )
    )
    .then((response: AxiosResponse) =>
      Promise.resolve(response.data as ApiUser)
    );

export const getActivityLog = async (
  getUser: GetUserFunction
): Promise<ApiActivityLog[]> => {
  return getUser().then(user => {
    return axios
      .get('/api/activityLog', {
        headers: buildHeaders(user as AuthedUser),
      })
      .then(response =>
        Promise.resolve((response.data as ApiGetActivityLogResponse).logEntries)
      );
  });
};

export const getActivityById = async (
  getUser: GetUserFunction,
  id: number
): Promise<ApiInteraction> => {
  return getUser().then(user => {
    return axios
      .get(`/api/interaction/${id}`, {
        headers: buildHeaders(user as AuthedUser),
      })
      .then(response => Promise.resolve(response.data));
  });
};

export const postInteraction = async (
  getUser: GetUserFunction,
  interaction: ApiInteraction
): Promise<ApiInteraction> =>
  getUser().then(user =>
    axios.post('/api/interaction', interaction, {
      headers: buildHeaders(user as AuthedUser),
    })
  );

export const putInteraction = async (
  getUser: GetUserFunction,
  interaction: ApiInteraction
): Promise<ApiInteraction> =>
  getUser().then(user =>
    axios.put('/api/interaction', interaction, {
      headers: buildHeaders(user as AuthedUser),
    })
  );

export const getReasonSuggestions = async (
  getUser: GetUserFunction,
  partialEntry: string
): Promise<string[]> => {
  const user = await getUser();
  const response = await axios.get('/api/config/suggestions/reasons', {
    headers: buildHeaders(user as AuthedUser),
    params: {
      partialEntry,
    },
  });
  return response.data.suggestions;
};

export const getActionSuggestions = async (
  getUser: GetUserFunction,
  partialEntry: string
): Promise<string[]> => {
  const user = await getUser();
  const response = await axios.get('/api/config/suggestions/actions', {
    headers: buildHeaders(user as AuthedUser),
    params: {
      partialEntry,
    },
  });
  return response.data.suggestions;
};

export const getCondtionSuggestions = async (
  getUser: GetUserFunction,
  partialEntry: string
): Promise<string[]> => {
  const user = await getUser();
  const response = await axios.get('/api/config/suggestions/conditions', {
    headers: buildHeaders(user as AuthedUser),
    params: {
      partialEntry,
    },
  });
  return response.data.suggestions;
};

export const getFollowUpActionSuggestions = async (
  getUser: GetUserFunction,
  partialEntry: string
): Promise<string[]> => {
  const user = await getUser();
  const response = await axios.get('/api/config/suggestions/followUpActions', {
    headers: buildHeaders(user as AuthedUser),
    params: {
      partialEntry,
    },
  });
  return response.data.suggestions;
};

export const getContactMethodSuggestions = async (
  getUser: GetUserFunction,
  partialEntry: string
): Promise<string[]> => {
  const user = await getUser();
  const response = await axios.get('/api/config/suggestions/contactMethods', {
    headers: buildHeaders(user as AuthedUser),
    params: {
      partialEntry,
    },
  });
  return response.data.suggestions;
};

export const getOutcomeSuggestions = async (
  getUser: GetUserFunction,
  partialEntry: string
): Promise<string[]> => {
  const user = await getUser();
  const response = await axios.get('/api/config/suggestions/outcomes', {
    headers: buildHeaders(user as AuthedUser),
    params: {
      partialEntry,
    },
  });
  return response.data.suggestions;
};

export const getSubtypeSuggestions = async (
  getUser: GetUserFunction,
  partialEntry: string
): Promise<string[]> => {
  const user = await getUser();
  const response = await axios.get('/api/config/suggestions/subtypes', {
    headers: buildHeaders(user as AuthedUser),
    params: {
      partialEntry,
    },
  });
  return response.data.suggestions;
};

export const getReferralSuggestions = async (
  getUser: GetUserFunction,
  partialEntry: string
): Promise<string[]> => {
  const user = await getUser();
  const response = await axios.get('/api/config/suggestions/referrals', {
    headers: buildHeaders(user as AuthedUser),
    params: {
      partialEntry,
    },
  });
  return response.data.suggestions;
};

export const getCommunitySuggestions = async (
  getUser: GetUserFunction,
  partialEntry: string
): Promise<string[]> => {
  const user = await getUser();
  const response = await axios.get('/api/config/suggestions/communities', {
    headers: buildHeaders(user as AuthedUser),
    params: {
      partialEntry,
    },
  });
  return response.data.suggestions;
};

export const getFollowUpTimeSuggestions = async (
  getUser: GetUserFunction,
  partialEntry: string
): Promise<string[]> => {
  const user = await getUser();
  const response = await axios.get('/api/config/suggestions/followUpTimes', {
    headers: buildHeaders(user as AuthedUser),
    params: {
      partialEntry,
    },
  });
  return response.data.suggestions;
};

export const getLocationSuggestions = async (
  getUser: GetUserFunction,
  partialEntry: string
): Promise<string[]> => {
  const user = await getUser();
  const response = await axios.get('/api/config/suggestions/locations', {
    headers: buildHeaders(user as AuthedUser),
    params: {
      partialEntry,
    },
  });
  return response.data.suggestions;
};

export const getClientAdvocacySuggestions = async (
  getUser: GetUserFunction,
  partialEntry: string
): Promise<string[]> => {
  const user = await getUser();
  const response = await axios.get('/api/config/suggestions/advocacies', {
    headers: buildHeaders(user as AuthedUser),
    params: {
      partialEntry,
    },
  });
  return response.data.suggestions;
};

import { UserAgentApplication, AuthResponse } from 'msal';

import { AuthedUser } from '../typings/types';
import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  getUserAgentApplication,
  authRequestObject,
} from '../utilities/getUserAgentApplication';
import { getUser as apiHandshake } from '../services/api';
import { ApiUser } from '../services/user';
import useIsMounted from './useIsMounted';
import { Permission, userCanFunction } from '../components/authContext';
import decode from 'jwt-decode';
import { clear as clearAllFromLocalStorage } from '../utilities/safeStorage';
import {
  CLEAR_RUNTIME_CACHE_COMMAND,
  SYNC_PENDING_INTERACTIONS_COMMAND,
  postMessage as postServiceWorkerMessage,
} from '../utilities/serviceWorker';
import useServiceWorkerCommandListener from './useServiceWorkerCommandListener';

export type GetUserFunction = () => Promise<AuthedUser | undefined>;

export const obtainAccessToken = async (
  msalObject: UserAgentApplication
): Promise<AuthResponse | undefined> => {
  try {
    const response = await msalObject.acquireTokenSilent(authRequestObject);
    return response;
  } catch (error) {
    if (
      error.errorCode === 'consent_required' ||
      error.errorCode === 'interaction_required' ||
      error.errorCode === 'login_required'
    ) {
      msalObject.acquireTokenRedirect(authRequestObject);
      return undefined;
    } else {
      return Promise.reject(error);
    }
  }
};

export const logIn = async (
  userAgentApplication: UserAgentApplication
): Promise<AuthedUser | undefined> => {
  if (userAgentApplication.getLoginInProgress()) {
    return Promise.reject(new Error('In progress'));
  }

  if (userAgentApplication.getAccount()) {
    try {
      const response = await obtainAccessToken(userAgentApplication);
      if (response === undefined) {
        return undefined;
      }

      const loggedInUser: AuthedUser = {
        name: response.account.name,
        email: response.account.userName,
        idToken: response.idToken.rawIdToken,
      };

      return loggedInUser;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  return undefined;
};

export interface UseAuthResults {
  loggedIn: boolean;
  loggingIn: boolean;
  getUser: GetUserFunction;
  apiUser?: ApiUser;
  changeLocation: (newLocation: string) => void;
  error: Error | undefined;
  logout: () => void;
  login: () => void;
  userCan: userCanFunction;
}

const useAuth = (): UseAuthResults => {
  const userAgentApplication = useMemo(() => getUserAgentApplication(), []);

  const isMounted = useIsMounted();

  /**
   * This should be updated to be a reducer at some point
   * We have functions that rely on and update multiple pieces of state at once
   * It is causing a lot of unnecessary renders
   */
  const [user, setUser] = useState<AuthedUser | undefined>(undefined);
  const [apiUser, setApiUser] = useState<ApiUser | undefined>(undefined);
  const updateApiUser = useRef<boolean>(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loggingIn, setLoggingIn] = useState(true);
  const [gettingApiUser, setGettingApiUser] = useState(false);
  const getUser = useCallback(async () => {
    const user = await logIn(userAgentApplication);
    if (isMounted.current) {
      setUser(user);
    }
    return user;
  }, [userAgentApplication, isMounted]);

  useServiceWorkerCommandListener(getUser);

  useEffect(() => {
    setLoggingIn(true);
    logIn(userAgentApplication)
      .then(user => {
        if (isMounted.current) setUser(user);
      })
      .catch(error => {
        if (isMounted.current) setError(error);
      })
      .finally(() => {
        if (isMounted.current) setLoggingIn(false);
      });
  }, [userAgentApplication, isMounted]);

  useEffect(() => {
    if (!updateApiUser.current || !user) return;
    updateApiUser.current = false;

    setGettingApiUser(true);
    apiHandshake(() => Promise.resolve(user))
      .then((apiUser: ApiUser) => {
        if (isMounted.current) {
          setApiUser(apiUser);
          postServiceWorkerMessage(SYNC_PENDING_INTERACTIONS_COMMAND);
        }
      })
      .catch(error => {
        if (isMounted.current) {
          console.error('Failed handshake: ', error);
          updateApiUser.current = true;
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setGettingApiUser(false);
        }
      });
  }, [user, isMounted]);

  const userCan = useCallback(
    (permission: Permission) => {
      if (!user) {
        return false;
      }

      interface Token {
        groups?: Permission[];
      }
      const parsedToken = decode<Token>(user.idToken);
      if (parsedToken.groups && parsedToken.groups.includes(permission)) {
        return true;
      } else {
        return false;
      }
    },
    [user]
  );

  return {
    loggingIn: loggingIn || gettingApiUser,
    loggedIn: !!user,
    getUser,
    apiUser,
    error,
    changeLocation: (newLocation: string): void => {
      apiUser &&
        setApiUser({
          ...apiUser,
          location: newLocation,
        });
    },
    logout: async (): Promise<void> => {
      if (!user) return;
      const getUserCache = await getUser();
      if (!getUserCache) {
        // TODO show notification of error
        console.warn('Could not log in');
        return;
      }

      setUser(undefined);
      setApiUser(undefined);

      clearAllFromLocalStorage();
      postServiceWorkerMessage(CLEAR_RUNTIME_CACHE_COMMAND);

      userAgentApplication.logout();
    },
    login: (): void => {
      userAgentApplication.loginRedirect({
        ...authRequestObject,
        prompt: 'login',
      });
    },
    userCan,
  };
};

export default useAuth;

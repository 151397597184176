export interface Configuration {
  name: string;
  items: Record<FormType, FormConfiguration>;
}

export enum FormType {
  Home = 'Home',
  Prevention = 'Prevention',
  Community = 'Community',
  Transport = 'Transport',
  Referral = 'Referral',
  PlannedMedical = 'PlannedMedical',
  Yes = 'Yes',
  Collaboration = 'Collaboration',
  ChildrenFamilySupport = 'ChildrenFamilySupport',
}

export const FormTypeValues = Object.values(FormType);

export const FORM_TYPE_NAME: Record<FormType, string> = {
  [FormType.Home]: 'Home',
  [FormType.Prevention]: 'Informal Impromptu Wellbeing Support',
  [FormType.Community]: 'Community Activities & Events',
  [FormType.Transport]: 'Safe Transport',
  [FormType.Referral]: 'Outreach Services',
  [FormType.PlannedMedical]: 'Planned Transport',
  [FormType.Yes]: 'Youth Engagement Support',
  [FormType.Collaboration]: 'Collaboration',
  [FormType.ChildrenFamilySupport]: 'Children & Family Support',
};

export const FORM_TYPE_TITLE: Record<FormType, string> = {
  [FormType.Home]: 'Home',
  [FormType.Prevention]: 'Informal Impromptu Wellbeing Support',
  [FormType.Community]: 'Community Activities & Events',
  [FormType.Transport]: 'New Trip',
  [FormType.Referral]: 'Outreach Services',
  [FormType.PlannedMedical]: 'Planned Transport',
  [FormType.Yes]: 'Youth Engagement Support',
  [FormType.Collaboration]: 'Collaboration',
  [FormType.ChildrenFamilySupport]: 'Children & Family Support',
};

export const FORM_TYPE_PAGE_URL: Record<FormType, string> = {
  [FormType.Home]: '/home',
  [FormType.Prevention]: '/new-interaction',
  [FormType.Community]: '/new-community',
  [FormType.Transport]: '/new-trip',
  [FormType.Referral]: '/new-referral',
  [FormType.PlannedMedical]: '/new-planned-medical',
  [FormType.Yes]: '/new-yes',
  [FormType.Collaboration]: '/new-collaboration',
  [FormType.ChildrenFamilySupport]: '/new-support',
};

export const FORM_TYPE_FIELDS: Record<FormType, (keyof FormConfiguration)[]> = {
  [FormType.Home]: [],
  [FormType.Transport]: [
    'referrers',
    'pickupLocations',
    'dropoffLocations',
    'communities',
    'riskLevels',
    'reasons',
    'conditions',
    'followUpActions',
    'followUpTimes',
  ],
  [FormType.Prevention]: [
    'actions',
    'locations',
    'referrers',
    'dropoffLocations',
    'communities',
    'riskLevels',
    'reasons',
    'outcomes',
    'conditions',
    'followUpActions',
    'followUpTimes',
  ],
  [FormType.Community]: [
    'subtypes',
    'actions',
    'locations',
    'referrers',
    'pickupLocations',
    'dropoffLocations',
    'communities',
  ],
  [FormType.Referral]: [
    'actions',
    'referrers',
    'communities',
    'riskLevels',
    'reasons',
    'outcomes',
    'conditions',
    'followUpActions',
    'followUpTimes',
    'contactMethods',
  ],
  [FormType.PlannedMedical]: [
    'referrers',
    'pickupLocations',
    'dropoffLocations',
    'communities',
    'riskLevels',
    'reasons',
    'conditions',
    'followUpActions',
    'followUpTimes',
  ],
  [FormType.Yes]: [
    'advocacyTypes',
    'subtypes',
    'actions',
    'referrers',
    'communities',
    'riskLevels',
    'outcomes',
    'conditions',
    'followUpActions',
    'followUpTimes',
    'contactMethods',
  ],
  [FormType.Collaboration]: [
    'subtypes',
    'advocacyTypes',
    'outcomes',
    'actions',
  ],
  [FormType.ChildrenFamilySupport]: [
    'subtypes',
    'reasons',
    'pickupLocations',
    'dropoffLocations',
    'advocacyTypes',
    'outcomes',
  ],
};

export const FORM_TYPE_FIELD_LABELS: Record<keyof FormConfiguration, string> = {
  dropoffLocations: 'Drop-off locations',
  pickupLocations: 'Pick-up locations',
  conditions: 'Customer conditions',
  reasons: 'Reasons',
  actions: 'Actions',
  outcomes: 'Outcomes',
  communities: 'Communities',
  referrers: 'Referrers',
  locations: 'Locations',
  followUpActions: 'Follow-Up actions',
  followUpTimes: 'Follow-Up times',
  ageGroups: 'Age groups',
  riskLevels: 'Risk-levels',
  subtypes: 'Subtypes',
  contactMethods: 'Contact methods',
  advocacyTypes: 'Advocacy types',
  agencies: 'Agencies',
  services: 'Services',
};

export const YES_NO = ['No', 'Yes'];

export interface FormConfiguration {
  dropoffLocations?: string[];
  pickupLocations?: string[];
  conditions?: string[];
  reasons?: string[];
  actions?: string[];
  outcomes?: string[];
  communities?: string[];
  referrers?: string[];
  locations?: string[];
  followUpActions?: string[];
  followUpTimes?: string[];
  ageGroups?: string[];
  riskLevels?: string[];
  subtypes?: string[];
  contactMethods?: string[];
  advocacyTypes?: string[];
  agencies?: string[];
  services?: string[];
}

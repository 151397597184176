import { Tally } from '../components/tallyTracker';

export interface PeopleCount {
  ageGroup: string;
  gender: string;
  count: number;
}

export function transformPeopleCountToTally(people: PeopleCount[]): Tally {
  const tally: Tally = {};
  if (people) {
    people.forEach((peopleCount: PeopleCount) => {
      if (!(peopleCount.ageGroup in tally)) {
        tally[peopleCount.ageGroup] = {};
      }
      tally[peopleCount.ageGroup][peopleCount.gender] = String(
        peopleCount.count
      );
    });
  }

  return tally;
}

export function transformTallyToPeopleCount(tally: Tally): PeopleCount[] {
  const peopleCounts: PeopleCount[] = [];
  if (tally) {
    Object.keys(tally).forEach(ageGroup => {
      Object.keys(tally[ageGroup]).forEach(gender => {
        peopleCounts.push({
          ageGroup,
          gender,
          count: Number(tally[ageGroup][gender]),
        });
      });
    });
  }
  return peopleCounts;
}

import {
  CircularProgress,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { DrawerProps } from '@material-ui/core/Drawer';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import useCommunitiesLogo from '../hooks/useCommunitiesLogo';
import useConfig from '../hooks/useConfig';
import useIsOnline from '../hooks/useIsOnline';
import {
  Activity,
  AdminConsole,
  Chat,
  ChildrenFamilySupport,
  Collaboration,
  Community,
  Location,
  Logout,
  Medical,
  Referral,
  Support,
  Transport,
} from '../icons';
import {
  FORM_TYPE_NAME,
  FORM_TYPE_PAGE_URL,
  FormType,
} from '../services/lists';
import { Permission, useAuthContext, userCanFunction } from './authContext';
import { useInteractionsContext } from './interactionsContext';
import LittleNumber from './littleNumber';
import LogOutConfirmation from './logOutConfirmation';

const useStyles = makeStyles(() => ({
  div: {
    width: '85vw',
    maxWidth: '350px',
  },
  imageDiv: {
    padding: '1rem',
    width: '100%',
    height: '7rem',
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    margin: 'auto',
    flex: '1 1 auto',
  },
}));

export interface InnerDrawerProps {
  interactionCounts: Record<FormType, number>;
  userCan: userCanFunction;
  gettingServices: boolean;
  services: FormType[];
  isOffline: boolean;
  shouldConfirmBeforeLoggingOut: boolean;
}

export const InnerDrawer: React.FC<InnerDrawerProps> = ({
  interactionCounts,
  userCan,
  gettingServices,
  services,
  isOffline,
  shouldConfirmBeforeLoggingOut,
}) => {
  const classes = useStyles();
  const { logout } = useAuthContext();
  const Logo = useCommunitiesLogo();
  const [logOutTapped, setLogOutTapped] = useState(false);

  return (
    <div className={classes.div}>
      <div className={classes.imageDiv}>
        <Img fluid={Logo} className={classes.image} />
      </div>
      {gettingServices ? (
        <CircularProgress color="inherit" />
      ) : (
        <>
          <Divider />
          <List component="div">
            {(services.length === 0 ||
              services.includes(FormType.Transport)) && (
              <ListItem
                button
                component={Link}
                to={FORM_TYPE_PAGE_URL[FormType.Transport]}
              >
                <ListItemIcon>
                  <Transport fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={FORM_TYPE_NAME.Transport} />
                <ListItemIcon>
                  <LittleNumber value={interactionCounts[FormType.Transport]} />
                </ListItemIcon>
              </ListItem>
            )}
            {(services.length === 0 ||
              services.includes(FormType.Community)) && (
              <ListItem
                button
                component={Link}
                to={FORM_TYPE_PAGE_URL[FormType.Community]}
              >
                <ListItemIcon>
                  <Community fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={FORM_TYPE_NAME.Community} />
                <ListItemIcon>
                  <LittleNumber value={interactionCounts[FormType.Community]} />
                </ListItemIcon>
              </ListItem>
            )}

            {(services.length === 0 ||
              services.includes(FormType.Prevention)) && (
              <ListItem
                button
                component={Link}
                to={FORM_TYPE_PAGE_URL[FormType.Prevention]}
              >
                <ListItemIcon>
                  <Chat fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={FORM_TYPE_NAME.Prevention} />
                <ListItemIcon>
                  <LittleNumber
                    value={interactionCounts[FormType.Prevention]}
                  />
                </ListItemIcon>
              </ListItem>
            )}
            {(services.length === 0 ||
              services.includes(FormType.PlannedMedical)) && (
              <ListItem
                button
                component={Link}
                to={FORM_TYPE_PAGE_URL[FormType.PlannedMedical]}
              >
                <ListItemIcon>
                  <Medical fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={FORM_TYPE_NAME.PlannedMedical} />
                <ListItemIcon>
                  <LittleNumber
                    value={interactionCounts[FormType.PlannedMedical]}
                  />
                </ListItemIcon>
              </ListItem>
            )}
            {(services.length === 0 ||
              services.includes(FormType.Referral)) && (
              <ListItem
                button
                component={Link}
                to={FORM_TYPE_PAGE_URL[FormType.Referral]}
              >
                <ListItemIcon>
                  <Referral fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={FORM_TYPE_NAME.Referral} />
                <ListItemIcon>
                  <LittleNumber value={interactionCounts[FormType.Referral]} />
                </ListItemIcon>
              </ListItem>
            )}
            {(services.length === 0 || services.includes(FormType.Yes)) && (
              <ListItem
                button
                component={Link}
                to={FORM_TYPE_PAGE_URL[FormType.Yes]}
              >
                <ListItemIcon>
                  <Support fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={FORM_TYPE_NAME.Yes} />
                <ListItemIcon>
                  <LittleNumber value={interactionCounts[FormType.Yes]} />
                </ListItemIcon>
              </ListItem>
            )}
            {(services.length === 0 ||
              services.includes(FormType.Collaboration)) && (
              <ListItem
                button
                component={Link}
                to={FORM_TYPE_PAGE_URL[FormType.Collaboration]}
              >
                <ListItemIcon>
                  <Collaboration fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={FORM_TYPE_NAME.Collaboration} />
                <ListItemIcon>
                  <LittleNumber
                    value={interactionCounts[FormType.Collaboration]}
                  />
                </ListItemIcon>
              </ListItem>
            )}
            {(services.length === 0 ||
              services.includes(FormType.ChildrenFamilySupport)) && (
              <ListItem
                button
                component={Link}
                to={FORM_TYPE_PAGE_URL[FormType.ChildrenFamilySupport]}
              >
                <ListItemIcon>
                  <ChildrenFamilySupport fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={FORM_TYPE_NAME.ChildrenFamilySupport} />
                <ListItemIcon>
                  <LittleNumber
                    value={interactionCounts[FormType.ChildrenFamilySupport]}
                  />
                </ListItemIcon>
              </ListItem>
            )}
            <ListItem button component={Link} to="/activity-log">
              <ListItemIcon>
                <Activity fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Activity log" />
            </ListItem>
          </List>
          <Divider />
          <List component="div">
            {userCan(Permission.AdministrateConsole) && (
              <ListItem
                disabled={isOffline}
                button
                component={Link}
                to="/administrate-config"
              >
                <ListItemIcon>
                  <AdminConsole fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Administrate configuration" />
              </ListItem>
            )}
            <ListItem
              disabled={isOffline}
              button
              component={Link}
              to="/set-location"
            >
              <ListItemIcon>
                <Location fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Change location" />
            </ListItem>
            <ListItem
              button
              onClick={(): void => {
                shouldConfirmBeforeLoggingOut
                  ? setLogOutTapped(true)
                  : logout();
              }}
            >
              <ListItemIcon>
                <Logout fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </List>

          <LogOutConfirmation
            showConfirmationDialog={
              shouldConfirmBeforeLoggingOut && logOutTapped
            }
            onConfirmHandle={(): void => {
              logout();
            }}
            onCancelHandle={(): void => setLogOutTapped(false)}
          />
        </>
      )}
    </div>
  );
};

const NavDrawer: React.FC<DrawerProps> = ({ ...props }) => {
  const {
    interactionCounts,
    totalInteractionCounts,
  } = useInteractionsContext();
  const { userCan } = useAuthContext();
  const { config, gettingConfig } = useConfig();
  const { isOnline } = useIsOnline();
  const services =
    config && config.items[FormType.Home]
      ? (config.items[FormType.Home].services as FormType[])
      : [];

  return (
    <Drawer {...props}>
      <InnerDrawer
        interactionCounts={interactionCounts}
        userCan={userCan}
        gettingServices={gettingConfig}
        services={services}
        isOffline={!isOnline}
        shouldConfirmBeforeLoggingOut={totalInteractionCounts > 0}
      />
    </Drawer>
  );
};

export default NavDrawer;

import React, { createContext, useContext } from 'react';
import useIsOnline from '../hooks/useIsOnline';

export interface OnlineContextValue {
  isOnline: boolean;
}

const OnlineContext = createContext<OnlineContextValue>({
  isOnline: true,
});
const OnlineContextProvider: React.FC = ({ children }) => {
  const value = useIsOnline();
  return (
    <OnlineContext.Provider value={value}>{children}</OnlineContext.Provider>
  );
};

export default OnlineContextProvider;

export const useOnlineContext = (): OnlineContextValue =>
  useContext(OnlineContext);

import { useConnectLogoQuery } from '../utilities/useConnectLogoQuery';
import { FixedObject } from 'gatsby-image';

const useAppLogo = (): FixedObject => {
  const data = useConnectLogoQuery();

  if (
    !data ||
    !data.file ||
    !data.file.childImageSharp ||
    !data.file.childImageSharp.fixed
  ) {
    throw Error('Could not retrieve the Connector Logo');
  }
  const fixed = data.file.childImageSharp.fixed;
  const result: FixedObject = {
    base64: fixed.base64 as string,
    width: fixed.width as number,
    height: fixed.height as number,
    src: fixed.src as string,
    srcSet: fixed.srcSet as string,
  };

  return result;
};

export default useAppLogo;

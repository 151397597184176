import {
  UserAgentApplication,
  Configuration,
  CacheLocation,
  AuthenticationParameters,
} from 'msal';

const authConfig: Configuration = {
  auth: {
    clientId: process.env.GATSBY_APP_CLIENT_ID || '', // This is your client ID
    authority: `https://login.microsoftonline.com/${process.env.GATSBY_APP_AUTHORITY}`,
    redirectUri:
      typeof window !== 'undefined' ? `${window.location.origin}/` : undefined,
    postLogoutRedirectUri:
      typeof window !== 'undefined' ? `${window.location.origin}/` : undefined,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage' as CacheLocation,
    storeAuthStateInCookie: true,
  },
};

export const authRequestObject: AuthenticationParameters = {
  scopes: [process.env.GATSBY_APP_CLIENT_ID as string],
};

export const getUserAgentApplication = (): UserAgentApplication => {
  const msalObject = new UserAgentApplication(authConfig);
  msalObject.handleRedirectCallback(
    () => undefined,
    authErr => {
      console.warn(authErr);
    }
  );

  return msalObject;
};

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export interface LogOutConfirmationProps {
  showConfirmationDialog: boolean;
  onConfirmHandle: () => void;
  onCancelHandle: () => void;
}

const LogOutConfirmation: React.FC<LogOutConfirmationProps> = ({
  showConfirmationDialog,
  onConfirmHandle,
  onCancelHandle,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={showConfirmationDialog}>
      <DialogTitle>
        <Typography variant="h6" component="h2">
          Confirm
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          By logging out, you will lose the data currently saved on this device.
          <br />
          Are you sure you want to proceed?
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              className={classes.button}
              onClick={onCancelHandle}
            >
              Go back
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              className={classes.button}
              onClick={onConfirmHandle}
            >
              Log out
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LogOutConfirmation;

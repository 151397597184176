import {
  AppBar,
  Badge,
  Fab,
  Grid,
  IconButton,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Link } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import React, { useState } from 'react';
import useAppLogo from '../hooks/useAppLogo';
import useIsOnline from '../hooks/useIsOnline';
import SettingsIcon from '../icons/settingsIcon';
import { useAuthContext } from './authContext';
import { useInteractionsContext } from './interactionsContext';
import NavDrawer from './navDrawer';
import OfflineIndicator from './offlineIndicator';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    minHeight: theme.spacing(10),
    alignItems: 'flex-start',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  iconColumn: {
    flexBasis: 'content',
    gap: '4px',
  },
  centeringMargin: {
    marginLeft: '64px',
  },
  backButton: {
    backgroundColor: theme.palette.primary.light,
  },
}));

interface UserDisplayProps {
  username?: string;
  location?: string;
}

const HeaderIcon: React.FC = () => {
  const [navOpen, setNavOpen] = useState(false);

  const {
    totalInteractionCounts: allInteractionCounts,
  } = useInteractionsContext();
  return (
    <>
      <IconButton
        aria-label="open drawer"
        onClick={(): void => {
          setNavOpen(true);
        }}
      >
        <Badge badgeContent={allInteractionCounts} color="secondary">
          <SettingsIcon />
        </Badge>
      </IconButton>
      <NavDrawer
        open={navOpen}
        onClose={(): void => {
          setNavOpen(false);
        }}
        anchor="right"
      />
    </>
  );
};

interface PureHeaderProps extends HeaderProps {
  classes: ReturnType<typeof useStyles>;
  logoData: FixedObject;
  location?: string;
  username?: string;
  isOffline: boolean;
}

const UserDisplay: React.FC<UserDisplayProps> = ({ username, location }) => {
  if (!username || !location) return <></>;
  const displayUsername = username.split('@')[0];
  return (
    <Typography variant="caption">
      {displayUsername} / {location}
    </Typography>
  );
};

export const PureHeader: React.FC<PureHeaderProps> = ({
  classes,
  headerIcon,
  navigateBackToPage,
  title,
  showUsername,
  username,
  titleAlignment,
  location,
  isOffline,
}) => (
  <AppBar color="primary" position="static">
    <Toolbar className={classes.toolbar}>
      <Grid container spacing={2} justify="space-between">
        {navigateBackToPage && (
          <Grid
            item
            className={classes.iconColumn}
            container
            direction="column"
            justify="center"
          >
            <Link to={navigateBackToPage} aria-label="go back">
              <IconButton className={classes.backButton}>
                <ArrowBack></ArrowBack>
              </IconButton>
            </Link>
          </Grid>
        )}
        <Grid container item xs direction="column" justify="center">
          {showUsername && (
            <UserDisplay location={location} username={username} />
          )}
          <Typography
            className={
              titleAlignment === 'center' && !navigateBackToPage
                ? classes.centeringMargin
                : ''
            }
            align={titleAlignment}
            variant="h5"
            component="h1"
          >
            {title}
          </Typography>
        </Grid>
        <Grid item container className={classes.iconColumn}>
          {isOffline && (
            <Fab size="small">
              <OfflineIndicator />
            </Fab>
          )}
          {headerIcon || (
            <Fab size="small">
              <HeaderIcon />
            </Fab>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);

export interface HeaderProps {
  title?: string;
  headerIcon?: React.ReactNode | null;
  navigateBackToPage?: string | null;
  showUsername?: boolean;
  titleAlignment?: string;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  headerIcon,
  navigateBackToPage,
  showUsername,
  titleAlignment,
}) => {
  const classes = useStyles();
  const logoData = useAppLogo();
  const {
    apiUser = { location: undefined, username: undefined },
  } = useAuthContext();
  const { isOnline } = useIsOnline();
  return (
    <PureHeader
      title={title}
      classes={classes}
      logoData={logoData}
      location={apiUser.location}
      username={apiUser.username}
      headerIcon={headerIcon}
      navigateBackToPage={navigateBackToPage}
      showUsername={showUsername}
      titleAlignment={titleAlignment}
      isOffline={!isOnline}
    />
  );
};

export default Header;

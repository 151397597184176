import React from 'react';
import { Grid, Theme, makeStyles, TextField } from '@material-ui/core';
import LabeledInput from './labeledInput';

const useStyles = makeStyles((theme: Theme) => ({
  tallyLabel: {
    marginBottom: theme.spacing(1),
  },
  input: {
    textAlign: 'center',
  },
  container: {
    alignItems: 'center',
    fontSize: theme.spacing(2),
    marginTop: '0.3rem',
  },
}));

export interface TallyDimensions {
  rowTitle: string;
  columns: string[];
  rows?: string[];
}

export type Tally = { [key: string]: { [key: string]: string } };

export const passThroughChange = (
  row: string,
  column: string,
  value: string,
  tally: Tally,
  onChange: (tally: Tally) => void
): void => {
  const parsedValue = Number(value);
  if (isNaN(parsedValue) || parsedValue < 0) value = '0';
  const update: Tally = { ...tally };
  update[row] = { ...tally[row] };
  update[row][column] = value;
  onChange(update);
};

export const initialiseTally = (
  dimensions: TallyDimensions,
  existingTally?: Tally
): Tally => {
  const tally: Tally = {};
  if (!!dimensions && !!dimensions.rows && !!dimensions.columns) {
    dimensions.rows.forEach(row => {
      tally[row] = {};
      dimensions.columns.forEach(column => {
        const existingValue =
          existingTally && existingTally[row] && existingTally[row][column];
        tally[row][column] = existingValue ? existingTally[row][column] : '0';
      });
    });
  }
  return tally;
};

export interface TallyTrackerProps {
  id: string;
  label: string;
  description: string;
  dimensions: TallyDimensions;
  onChange: (tally: Tally) => void;
  tally?: Tally;
}

export const TallyTracker: React.FC<TallyTrackerProps> = ({
  id,
  label,
  description,
  dimensions,
  onChange,
  tally,
}) => {
  const classes = useStyles();
  const elements: JSX.Element[] = [];

  if (!tally || !dimensions || !dimensions.rows) {
    return <></>;
  }

  dimensions.rows.forEach((row: string) => {
    elements.push(
      <Grid key={`${row}-tally-header`} item xs={4}>
        {row}
      </Grid>
    );

    if (tally[row]) {
      dimensions.columns.forEach(column => {
        elements.push(
          <Grid key={`${row}-${column}-value`} item xs={4}>
            <TextField
              id={`${row}-${column}`}
              name={`${row}-${column}`}
              onFocus={(e): void => {
                if (e.target.value === '0')
                  passThroughChange(row, column, '', tally, onChange);
              }}
              onBlur={(e): void => {
                if (e.target.value === '')
                  passThroughChange(row, column, '0', tally, onChange);
              }}
              InputProps={{
                'aria-label': `${row} ${column} count`,
                classes: {
                  input: classes.input,
                },
              }}
              value={tally[row][column]}
              onChange={(e): void => {
                passThroughChange(row, column, e.target.value, tally, onChange);
              }}
              type="number"
              inputMode="numeric"
              inputProps={{
                pattern: '[0-9]*',
              }}
              margin="none"
              variant="outlined"
            />
          </Grid>
        );
      });
    }
  });
  return (
    <LabeledInput id={id} label={label} description={description}>
      <Grid id={id} className={classes.container} container spacing={1}>
        <Grid className={classes.tallyLabel} item xs={4}>
          {dimensions.rowTitle}
        </Grid>
        <>
          {dimensions.columns.map(column => {
            return (
              <Grid
                key={`${column}-tally-header`}
                className={classes.tallyLabel}
                item
                xs={4}
              >
                {column}
              </Grid>
            );
          })}
        </>
        {elements}
      </Grid>
    </LabeledInput>
  );
};

export default TallyTracker;

export const defaultDimensions = {
  columns: ['Male', 'Female'],
  rowTitle: 'Ages',
  rows: undefined,
};

export const tallyTotal = (tally?: Tally): number => {
  if (!tally) {
    return 0;
  } else {
    return Object.keys(tally).reduce(
      (total, r) =>
        total +
        Object.keys(tally[r]).reduce(
          (rowTotal, c) => rowTotal + Number(tally[r][c]),
          0
        ),
      0
    );
  }
};

export const tallyHasJuvenile = (tally: Tally): boolean =>
  Object.keys(tally).some(key =>
    key === '18+'
      ? false
      : Object.values(tally[key]).some(value => Number(value) > 0)
  );

import { useEffect } from 'react';
import {
  BROADCAST_CHANNEL,
  REQUEST_TOKEN_COMMAND,
  USER_TOKEN_RESULT,
} from '../utilities/serviceWorker';
import { AuthedUser } from '../typings/types';

const useServiceWorkerCommandListener = (
  getUser: () => Promise<AuthedUser | undefined>
): void => {
  useEffect(() => {
    const channel = new BroadcastChannel(BROADCAST_CHANNEL);
    channel.addEventListener('message', event => {
      if (event.data === REQUEST_TOKEN_COMMAND) {
        getUser().then(user => {
          channel.postMessage(`${USER_TOKEN_RESULT} ${user?.idToken}`);
        });
      }
    });

    return (): void => {
      channel.close();
    };
  }, []);
};

export default useServiceWorkerCommandListener;

/**
 * Trips is a local storage interface service.
 * When you use this service to initialise a page, make sure you do it from useEffect.
 * Otherwise, gatsby will attempt to access localStorage during the server side build, and throw an error
 * */
import * as safeStorage from '../utilities/safeStorage';
import * as yup from 'yup';
import { IndividualClient, Interaction } from '../services/interactions';
import { postInteraction, putInteraction } from './api';
import { GetUserFunction } from '../hooks/useAuth';
import { interactionToApiInteraction } from './interactions';

const STORAGE_KEY = 'trips';

export const getTrips = (): Interaction[] => {
  const storedTrips = safeStorage.getItem(STORAGE_KEY);
  return storedTrips ? JSON.parse(storedTrips) : [];
};

export const saveTrips = (trips: Interaction[]): Interaction[] => {
  safeStorage.setItem(STORAGE_KEY, JSON.stringify(trips));
  return trips;
};

export const addTrip = (trip: Interaction): void => {
  const trips = getTrips();
  trips.push(trip);
  saveTrips(trips);
};

export const editTrip = (trip: any): boolean => {
  const trips = getTrips();
  const oldTripIndex = trips.findIndex(({ id }) => id === trip.id);
  if (oldTripIndex < 0) return false;

  trips[oldTripIndex] = { ...trip };
  saveTrips(trips);
  return true;
};

export const getTrip = (id: string): Interaction | null => {
  const trips = getTrips();
  const index = trips.map(trip => trip.id).indexOf(id);
  if (index < 0) {
    return null;
  }
  const trip = trips[index];
  return trip;
};

export const removeTrip = (id: string): Interaction[] => {
  const trips = getTrips();
  const removeIndex = trips.findIndex(trip => trip.id === id);
  if (removeIndex < 0) {
    // trip does not exist
    return trips;
  }
  trips.splice(removeIndex, 1);
  return saveTrips(trips);
};

export const completeTrip = async (
  id: string,
  getUser: GetUserFunction
): Promise<Interaction[]> => {
  const trips = getTrips();
  const trip = trips.find(({ id: tripId }) => tripId === id);
  if (!trip) {
    throw new Error('You tried to complete trip that does not exist');
  }

  trip.apiId
    ? await putInteraction(getUser, interactionToApiInteraction(trip))
    : await postInteraction(getUser, interactionToApiInteraction(trip));
  return removeTrip(id);
};

export const tripSchema = yup.object().shape<Interaction>({
  formType: yup.mixed().required(),
  id: yup.string().required(),
  name: yup.string(),
  dropOffLocation: yup.string().required(),
  timeOccurred: yup.string().required(),
  pickupLocation: yup.string().required(),
  community: yup.string(),
  condition: yup
    .array()
    .of(yup.string())
    .required(),
  referredBy: yup.string().required(),
  repeatCustomer: yup.string().required(),
  reasons: yup
    .array()
    .of(yup.string())
    .required(),
  followUp: yup.string().required(),
  followUpActions: yup
    .array()
    .of(yup.string())
    .when(['followUp'], {
      is: followUp => followUp === 'Yes',
      then: yup
        .array()
        .of(yup.string())
        .required(),
    }),
  individualClients: yup.array().of(yup.object<IndividualClient>()),
});

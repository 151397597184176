import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Location: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 496 496" {...props}>
    <g>
      <g>
        <g>
          <path d="M360,72c-74.992,0-136,61.008-136,136v1.952c0,26.456,7.64,52.152,22.088,74.296l94.84,145.432 C345.152,436.144,352.28,440,360,440c7.72,0,14.848-3.856,19.072-10.328l94.84-145.432C488.36,262.096,496,236.408,496,209.952 				V208C496,133.008,434.992,72,360,72z M480,209.952c0,23.344-6.744,46.008-19.496,65.552l-94.84,145.424	c-2.496,3.84-8.832,3.84-11.328,0l-94.84-145.424C246.744,255.96,240,233.296,240,209.952V208c0-66.168,53.832-120,120-120 s120,53.832,120,120V209.952z" />
          <rect x="256" y="344" width="16" height="56" />
          <path d="M16,64h240v24h16V40c0-22.056-17.944-40-40-40H40C17.944,0,0,17.944,0,40v360h16V64z M16,40c0-13.232,10.768-24,24-24 h192c13.232,0,24,10.768,24,24v8H16V40z" />
          <path d="M0,456c0,22.056,17.944,40,40,40h192c22.056,0,40-17.944,40-40v-40H0V456z M16,432h240v24c0,13.232-10.768,24-24,24H40	c-13.232,0-24-10.768-24-24V432z" />
          <rect x="104" y="448" width="64" height="16" />
          <path d="M240,120V80H32v320h208v-96h-16v80h-32V224h16v-16h-32v176h-32v-64H48v-32h96v-80H48v-32h48V96h32v80h80v-16h-64V96h80 v24H240z M128,336v48H48v-48H128z M128,224v48H48v-48H128z M80,160H48V96h32V160z" />
          <path d="M360,120c-48.52,0-88,39.48-88,88s39.48,88,88,88c48.52,0,88-39.48,88-88C448,159.48,408.52,120,360,120z M360,280 c-39.704,0-72-32.304-72-72s32.296-72,72-72c39.704,0,72,32.304,72,72S399.704,280,360,280z" />
          <rect x="320" y="200" width="16" height="16" />
          <rect x="352" y="200" width="16" height="16" />
          <rect x="384" y="200" width="16" height="16" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default Location;

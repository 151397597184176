import { Queue, QueueEntry } from 'workbox-background-sync';
import { ApiGetActivityLogResponse } from '../services/activityLogs.types';
import {
  PendingSyncInteraction,
  pendingSyncInteractionToApiActivityLogEntry,
} from '../services/interactions.types';

export const BROADCAST_CHANNEL = 'broadcastChannel';

export const PENDING_SYNC_INTERACTIONS_QUEUE = 'pendingSyncInteractionsQueue';
export const SUBMITTED_INTERACTIONS_QUEUE = 'submittedInteractionsQueue';

export const CLEAR_RUNTIME_CACHE_COMMAND = 'clearRuntimeCache';
export const PENDING_SYNC_INTERACTIONS_RESULT = '__pendingSyncInteractions__';
export const REQUEST_PENDING_SYNC_INTERACTIONS_COMMAND =
  'requestPendingSyncInteractions';
export const REQUEST_TOKEN_COMMAND = 'requestToken';
export const SYNC_PENDING_INTERACTIONS_COMMAND = 'syncPendingInteractions';
export const USER_TOKEN_RESULT = 'Bearer';

export const ServiceWorkMessage = [
  CLEAR_RUNTIME_CACHE_COMMAND,
  PENDING_SYNC_INTERACTIONS_RESULT,
  REQUEST_PENDING_SYNC_INTERACTIONS_COMMAND,
  REQUEST_TOKEN_COMMAND,
  SYNC_PENDING_INTERACTIONS_COMMAND,
  USER_TOKEN_RESULT,
] as const;
export type ServiceWorkMessageType = typeof ServiceWorkMessage[number];

export const isServiceWorkerCommand = (
  value: any
): value is ServiceWorkMessageType => {
  switch (value) {
    case CLEAR_RUNTIME_CACHE_COMMAND:
    case REQUEST_PENDING_SYNC_INTERACTIONS_COMMAND:
    case REQUEST_TOKEN_COMMAND:
    case SYNC_PENDING_INTERACTIONS_COMMAND:
      return true;
    default:
      return false;
  }
};

export const isServiceWorkerCommandResult = (
  value: any
): value is ServiceWorkMessageType => {
  return (
    value.startsWith(USER_TOKEN_RESULT) ||
    value.startsWith(PENDING_SYNC_INTERACTIONS_RESULT)
  );
};

/** Functions to be called from the React app */
export const postMessage = (message: ServiceWorkMessageType): void => {
  if (!!navigator?.serviceWorker?.controller) {
    navigator.serviceWorker.controller.postMessage(message);
  }
};

/** Functions to be called from the service-worker */
export const fetchUserToken = (channel: BroadcastChannel): Promise<string> => {
  return new Promise(resolve => {
    channel.onmessage = ({ data }): void => {
      if (
        isServiceWorkerCommandResult(data) &&
        data.startsWith(USER_TOKEN_RESULT)
      ) {
        resolve(data);
      }
    };
    channel.postMessage(REQUEST_TOKEN_COMMAND);
  });
};

export const getPendingSyncInteractions = async (
  queue: Queue
): Promise<PendingSyncInteraction[]> => {
  const queuedEntries = await queue.getAll();
  return (await Promise.all(
    queuedEntries.map(e => {
      return e.request.clone().json();
    })
  )) as PendingSyncInteraction[];
};

export const syncPendingInteractions = async (
  queue: Queue,
  broadcastChannel: BroadcastChannel
): Promise<void> => {
  console.log('Service-Worker: Syncing pending interactions in progress...');

  let entry: QueueEntry<any>;
  const bearerToken = await fetchUserToken(broadcastChannel);

  while ((entry = await queue.shiftRequest())) {
    console.log('Syncing pending interaction...');
    const headers: any = {};
    entry.request.headers.forEach((value, key) => {
      headers[key] = value;
    });
    headers['authorization'] = bearerToken;

    try {
      const retryRequest = new Request(entry.request, { headers });
      await fetch(retryRequest);
    } catch (err) {
      console.error('Error while syncing entry...', entry);
      queue.unshiftRequest(entry);
    }
  }

  console.log('Service-Worker: Syncing pending interactions done ✅');
};

export const interceptUpsertInteractionRequest = (
  event: any,
  queue: Queue
): Promise<Response> => {
  return fetch(event.request.clone())
    .then(async response => {
      return response;
    })
    .catch(async error => {
      // Fall-through for network error - queue the request to be replayed when service-worker gains connectivity back
      await queue.pushRequest({
        request: event.request,
      });
      return error;
    });
};

export const interceptActivityLogGetRequest = async (
  event: any,
  queue: Queue
): Promise<Response> => {
  const pendingSyncInteractions = await getPendingSyncInteractions(queue);
  const pendingSyncActivityLog = pendingSyncInteractions.map(i =>
    pendingSyncInteractionToApiActivityLogEntry(i)
  );

  return fetch(event.request.clone())
    .then(async response => {
      // Augment the Activity Log response from the API with the pending Sync Interactions
      const originalResponse = response.clone();
      const apiActivityLog = (await originalResponse.json()) as ApiGetActivityLogResponse;
      const updatedLogEntries = [
        ...apiActivityLog.logEntries,
        ...pendingSyncActivityLog,
      ];

      const newResponseBody = {
        ...apiActivityLog,
        logEntryCount: updatedLogEntries.length,
        logEntries: updatedLogEntries,
      };

      return new Response(JSON.stringify(newResponseBody), {
        status: originalResponse.status,
        statusText: originalResponse.statusText,
        headers: new Headers(originalResponse.headers),
      });
    })
    .catch(async () => {
      // Fall-through for network error - return a Response with pending sync interactions only
      const newResponseBody = {
        logEntryCount: pendingSyncActivityLog.length,
        logEntries: pendingSyncActivityLog,
      };

      return new Response(JSON.stringify(newResponseBody), {
        status: 200,
      });
    });
};

export const purgeOldPreCachedRequests = async (
  cacheName: string,
  requestsToKeep: { url: string; revision?: string }[]
): Promise<void> => {
  const cache = await caches.open(cacheName);
  const cachedRequests = await cache.keys();

  const urlPathsToKeep = requestsToKeep.map(r =>
    r.revision ? `${r.url}?__WB_REVISION__=${r.revision}` : r.url
  );

  for (const request of cachedRequests) {
    if (!urlPathsToKeep.find(urlPath => request.url.endsWith(urlPath))) {
      console.log('Deleting old pre-cached request', request);
      await cache.delete(request);
    }
  }
};

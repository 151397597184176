import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Pencil: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 28.273 28.273" {...props}>
    <path d="M26.617,1.657a5.657,5.657,0,0,0-8,0L2.807,17.467a1.1,1.1,0,0,0-.283.485L.041,26.873a1.1,1.1,0,0,0,1.366,1.359L10.328,25.7a1.1,1.1,0,0,0,.48-1.842L5.184,18.214l9.952-9.952,4.869,4.869L13.13,19.987a1.1,1.1,0,1,0,1.56,1.564L26.617,9.657a5.657,5.657,0,0,0,0-8ZM7.919,24.085,2.7,25.569l1.463-5.254ZM21.57,11.571,16.7,6.7l1.586-1.586,4.874,4.874Zm3.486-3.477-.334.333L19.846,3.552l.333-.333a3.448,3.448,0,0,1,4.877,4.875Z" />
  </SvgIcon>
);

export default Pencil;

import { useStaticQuery, graphql } from 'gatsby';
import { CommunitiesLogoQuery } from '../typings/graphql';

export const useCommunitiesLogoQuery = (): CommunitiesLogoQuery =>
  useStaticQuery<CommunitiesLogoQuery>(graphql`
    query CommunitiesLogo {
      file(relativePath: { eq: "communities-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

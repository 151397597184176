/**
 * Purpose:
 * The "form" a user fills out while completing an interaction
 * is complex. It sometimes requires the user to navigate
 * multiple pages to fill out information on specific clients.
 *
 * This context is used to maintain the state of a form across
 * multiple page navigations.
 */

import React, { createContext, useState, useContext, useEffect } from 'react';
import { useCallback } from 'react';
import { FormState } from '../forms/form';
import { FormType } from '../services/lists';
import * as safeStorage from '../utilities/safeStorage';

const isBrowser = typeof window !== 'undefined';

const STORAGE_KEY = 'CURRENT_INTERACTION';

export interface FormContextValue {
  form?: FormState;
  saveForm: (form: FormState) => void;
  clearForm: () => void;
}

const FormContext = createContext<FormContextValue>({
  form: undefined,
  saveForm: () => {
    /*void*/
  },
  clearForm: () => {
    /*void*/
  },
});

/**
 * @private
 * @description
 * Do not use in React component
 * This hook is only exported for testing purposes
 */
export const useForm = (): FormContextValue => {
  const [form, setForm] = useState<FormState>();
  const [loading, setLoading] = useState(true);
  let firstLoadForm: FormState | undefined;

  if (loading && isBrowser) {
    const storedForm = safeStorage.getItem(STORAGE_KEY);
    firstLoadForm = storedForm ? JSON.parse(storedForm) : undefined;
  } else if (loading && !isBrowser) {
    firstLoadForm = new FormState(FormType.Transport);
  }

  useEffect(() => {
    const storedForm = safeStorage.getItem(STORAGE_KEY);
    setForm(storedForm ? JSON.parse(storedForm) : undefined);
    setLoading(false);
  }, []);

  const saveForm = useCallback((newForm: FormState) => {
    safeStorage.setItem(STORAGE_KEY, JSON.stringify(newForm));
    setForm(newForm);
  }, []);

  const clearForm = useCallback(() => {
    safeStorage.removeItem(STORAGE_KEY);
    setForm(undefined);
  }, []);

  return {
    form: form || firstLoadForm,
    saveForm,
    clearForm,
  };
};

const FormContextProvider: React.FC = ({ children }) => {
  const value = useForm();
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export default FormContextProvider;

export const useFormContext = (): FormContextValue => useContext(FormContext);

import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../hooks/useSiteMetadata';

const Head: React.FC = () => {
  const { title, description, author } = useSiteMetadata();
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title || 'Connect'}</title>
      {description && <meta name="description" content={description} />}
      {author && <meta name="author" />}
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta name="robots" content="noindex, nofollow, nosnippet" />
      <html lang="en" />
    </Helmet>
  );
};

export default Head;

import React, { useMemo } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import { InputBase, Chip, makeStyles, Grid } from '@material-ui/core';
import { newMoment } from '../utilities/moment';

export const HTML_DATE_INPUT_FORMAT = 'YYYY-MM-DDTHH:mm';

const useStyles = makeStyles({
  timeInput: {
    backgroundColor: 'transparent',
    color: 'white',
  },
});

type DateTimeProps = Omit<
  TextFieldProps,
  | 'variant'
  | 'margin'
  | 'name'
  | 'fullWidth'
  | 'id'
  | 'label'
  | 'type'
  | 'onChange'
> & {
  label: string;
  name: string;
  id: string;
  value?: string;
  onChange: (date: string) => void;
};

interface PureDateTimeProps extends DateTimeProps {
  maxDate: string;
}

export const PureDateTime: React.FC<PureDateTimeProps> = ({
  id,
  name,
  value,
  onChange,
  maxDate,
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-end" direction="column">
      <Chip
        color="secondary"
        label={
          <InputBase
            classes={{ root: classes.timeInput }}
            id={id}
            type="datetime-local"
            name={name}
            value={value}
            inputProps={{
              max: maxDate,
            }}
            onChange={(event): void => {
              onChange(event.target.value);
            }}
          />
        }
      />
    </Grid>
  );
};

export const DateTime: React.FC<DateTimeProps> = props => {
  const midnightTomorrow = useMemo(() => {
    return newMoment(0, 'HH')
      .add(1, 'days')
      .format(HTML_DATE_INPUT_FORMAT);
  }, []);
  return <PureDateTime maxDate={midnightTomorrow} {...props} />;
};

export default DateTime;

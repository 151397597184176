import React from 'react';
import Header, { HeaderProps } from './header';
import ConnectTheme from '../theme/themeProvider';
import MomentUtils from '@date-io/moment';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { Card, Container, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Head from './head';
import { GridJustification } from '@material-ui/core';

export interface LayoutProps {
  noFooter?: boolean;
  wrapper?: boolean;
  navigateBackToPage?: string | null;
  bottomShim?: boolean;
  justify?: GridJustification;
}

const useStyles = makeStyles((theme: Theme) => ({
  parent: {
    position: 'relative',
  },
  mainGrid: {
    minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    paddingTop: theme.spacing(4),
    zIndex: 1,
  },
  mainCard: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '40px 40px 0 0',
  },
  bottomShim: {
    borderRadius: '0 40px 0 0',
    width: '90%',
    backgroundColor: theme.palette.secondary.main,
    height: '9rem',
    position: 'absolute',
    bottom: '0',
    left: 0,
    zIndex: 0,
  },
}));

export interface WrapperProps {
  showWrapper: boolean;
  children: React.ReactNode;
}

const ConditionalWrapper: React.FC<WrapperProps> = ({
  children,
  showWrapper,
}) => {
  const classes = useStyles();
  if (showWrapper) {
    return <Card className={classes.mainCard}> {children} </Card>;
  } else {
    return children;
  }
};

const BottomShim: React.FC = () => {
  const classes = useStyles();
  return <Card className={classes.bottomShim}></Card>;
};

const Layout: React.FC<LayoutProps & HeaderProps> = ({
  children,
  title = '',
  wrapper = true,
  navigateBackToPage = '/home',
  showUsername = false,
  bottomShim = false,
  titleAlignment = 'left',
  justify = 'flex-start',
  headerIcon,
}) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Head />
      <ConnectTheme>
        <Grid
          direction="column"
          alignItems="stretch"
          container
          className={classes.parent}
        >
          {bottomShim && <BottomShim />}

          <Header
            title={title}
            headerIcon={headerIcon}
            navigateBackToPage={navigateBackToPage}
            showUsername={showUsername}
            titleAlignment={titleAlignment}
          />
          <ConditionalWrapper showWrapper={wrapper}>
            <Container fixed>
              <Grid
                className={classes.mainGrid}
                container
                direction="column"
                justify={justify}
                alignItems="center"
              >
                {children}
              </Grid>
            </Container>
          </ConditionalWrapper>
        </Grid>
      </ConnectTheme>
    </MuiPickersUtilsProvider>
  );
};

export default Layout;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Activity: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <g>
      <circle cx="166" cy="226" r="10" />
      <circle cx="166" cy="286" r="10" />
      <circle cx="166" cy="346" r="10" />
      <circle cx="166" cy="406" r="10" />
      <path d="m226 236h120c5.522 0 10-4.478 10-10s-4.478-10-10-10h-120c-5.522 0-10 4.478-10 10s4.478 10 10 10z" />
      <path d="m226 296h120c5.522 0 10-4.478 10-10s-4.478-10-10-10h-120c-5.522 0-10 4.478-10 10s4.478 10 10 10z" />
      <path d="m226 356h120c5.522 0 10-4.478 10-10s-4.478-10-10-10h-120c-5.522 0-10 4.478-10 10s4.478 10 10 10z" />
      <path d="m226 416h120c5.522 0 10-4.478 10-10s-4.478-10-10-10h-120c-5.522 0-10 4.478-10 10s4.478 10 10 10z" />
      <path d="m406 90h-81.266c-3.626-14.035-14.698-25.108-28.734-28.734v-21.266c0-22.056-17.944-40-40-40s-40 17.944-40 40v21.266c-14.035 3.626-25.108 14.699-28.734 28.734h-81.266c-5.522 0-10 4.478-10 10v402c0 5.522 4.478 10 10 10h105c5.522 0 10-4.478 10-10s-4.478-10-10-10h-95v-382h70v20c0 5.522 4.478 10 10 10h120c5.522 0 10-4.478 10-10v-20h70v382h-95c-5.522 0-10 4.478-10 10s4.478 10 10 10h105c5.522 0 10-4.478 10-10v-402c0-5.522-4.477-10-10-10zm-170-50c0-11.028 8.972-20 20-20s20 8.972 20 20v20h-40zm70 80h-100v-20c0-11.028 8.972-20 20-20h60c11.028 0 20 8.972 20 20z" />
      <circle cx="256" cy="502" r="10" />
    </g>
  </SvgIcon>
);

export default Activity;

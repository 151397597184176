import React from 'react';
import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) => ({
  bottomContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

const FooterButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  const { bottomContainer } = useStyles();
  return (
    <Grid container item justify="center" className={bottomContainer}>
      <Grid item xs={9} lg={6}>
        <Button size="large" color="secondary" variant="contained" {...props}>
          {children ? children : 'SUBMIT'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FooterButton;

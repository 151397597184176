import { createMuiTheme } from '@material-ui/core';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const BLUE = '#22669A';
export const NAVY_BLUE = '#0B395C';
export const CYAN = '#2BB5C2';
export const CYAN_DARK = '#2476A2';
export const CYAN_LIGHT = '#7FD2D3';
export const STEEL_BLUE = '#1B527C';
export const WHITE = '#ffffff';

const theme: ThemeOptions = {
  typography: {
    button: {
      textTransform: 'capitalize',
      lineHeight: 1.4,
    },
  },
  shape: {
    borderRadius: 4,
  },
  shadows: new Array(25).fill('none', 0, 25) as ThemeOptions['shadows'],
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: BLUE,
          color: WHITE,
        },
      },
    },
    MuiFab: {
      root: {
        backgroundColor: STEEL_BLUE,
        color: WHITE,
        '&:hover': {
          backgroundColor: NAVY_BLUE,
          color: WHITE,
        },
      },
    },
    MuiSvgIcon: {
      colorSecondary: {
        color: CYAN_LIGHT,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'hsla(0, 0%, 100%, 0.8)',
      },
    },
    MuiBadge: {
      colorSecondary: {
        border: `1px solid ${WHITE}`,
        color: WHITE,
      },
    },
    MuiCard: {
      root: {
        borderRadius: '1rem',
        backgroundColor: BLUE,
      },
    },
    MuiCardHeader: {
      root: {
        backgroundColor: STEEL_BLUE,
      },
    },
    MuiIconButton: {
      root: {
        color: WHITE,
        '&:hover': {
          color: CYAN,
        },
      },
    },
    MuiButton: {
      root: {
        height: '3.1rem',
        fontWeight: 400,
        fontSize: '1rem',
        textTransform: 'none',
      },
      contained: {
        width: '100%',
        '&$disabled': {
          backgroundColor: STEEL_BLUE,
          color: 'hsla(0,0%,100%,0.45)',
        },
      },
      sizeLarge: {
        fontSize: '1rem',
        fontWeight: 500,
      },
      outlinedPrimary: {
        backgroundColor: WHITE,
        borderColor: NAVY_BLUE,
        '&:hover': {
          '@media(hover: none) ': {
            backgroundColor: WHITE,
            borderColor: NAVY_BLUE,
          },
          backgroundColor: WHITE,
          borderColor: NAVY_BLUE,
        },
      },
      outlinedSecondary: {
        backgroundColor: STEEL_BLUE,
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: STEEL_BLUE,
        },
      },
      containedSecondary: {
        textTransform: 'uppercase',
      },
    },
    MuiInputLabel: {
      formControl: {
        top: 'auto',
        left: 'auto',
        position: 'relative',
        transform: 'auto',
      },
    },
    MuiTypography: {
      h6: {
        fontWeight: 400,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '0.5rem',
      },
    },
    MuiDialogContent: {
      root: {
        backgroundColor: NAVY_BLUE,
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: STEEL_BLUE,
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
        backgroundColor: NAVY_BLUE,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.175rem',
        lineHeight: '1.5rem',
        fontWeight: 400,
        '&$focused': {
          color: 'inherit',
          fontWeight: 'inherit',
        },
      },
    },
    MuiAppBar: {
      root: {
        background: BLUE,
      },
    },
    MuiInputBase: {
      root: {
        background: BLUE,
      },
    },
    MuiExpansionPanel: {
      root: {
        backgroundColor: NAVY_BLUE,
      },
    },
    MuiChip: {
      outlinedSecondary: {
        color: WHITE,
        backgroundColor: CYAN_DARK,
        borderRadius: 4,
      },
    },
  },
  palette: {
    text: {
      primary: WHITE,
      secondary: WHITE,
    },
    primary: {
      main: BLUE,
      dark: NAVY_BLUE,
      contrastText: WHITE,
    },
    secondary: {
      main: CYAN,
      contrastText: NAVY_BLUE,
    },
    background: {
      default: BLUE,
      paper: BLUE,
    },
  },
};

export const darkTheme = createMuiTheme(theme);

export const ID_CHARSET = 'ABCDEFGHKMNPQRSTUVWXYZ23456789';
export const ID_LENGTH = 6;

// This behaviour should logically mirror the way the API generates friendly IDs:
// ConnectApi\ConnectApi.Core\Services\IFriendlyIdGenerator.cs::GenerateRandomString
export const generateId = (): string => {
  let id = '';
  for (let i = 0; i < ID_LENGTH; i++) {
    const index = Math.floor(Math.random() * ID_CHARSET.length);
    id += ID_CHARSET[index];
  }
  return id;
};

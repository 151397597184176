import React, { Component, ReactNode } from 'react';
import Layout from './layout';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import CenterHeader from './centerHeader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import Footer from './footer';
import FooterButton from './footerButton';

type ErrorInfo = {
  componentStack: string;
};

export interface ErrorBoundryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<{}, ErrorBoundryState> {
  constructor(props: {}) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render(): ReactNode {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Layout>
          <CenterHeader>Something went wrong</CenterHeader>
          <Typography variant="h6" component="h2">
            {this.state.error && this.state.error.toString()}
          </Typography>
          <div style={{ marginBottom: '2rem' }}>
            <MoodBadIcon fontSize="large" />
          </div>
          <div style={{ width: '100%', maxWidth: '24rem' }}>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="error-panel-content"
                id="error-panel-header"
              >
                <Typography>Details</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.errorInfo.componentStack}
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <Footer>
            <FooterButton
              type="button"
              onClick={(): void => {
                this.setState({ error: null, errorInfo: null });
              }}
            >
              RELOAD
            </FooterButton>
          </Footer>
        </Layout>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;

import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  InitialInteractionCounts,
  Interaction,
  InteractionCounts,
  getLocalAll,
  getLocalCounts,
  getTotalLocalCounts,
  removeLocal,
  saveLocal,
  submitToApi,
} from '../services/interactions';
import { FormType } from '../services/lists';
import { useAuthContext } from './authContext';
import { isNetworkError } from '../utilities/axios';

export enum InteractionSubmitResponse {
  SUCCESS = 'SUCCESS',
  NETWORK_ERROR = 'NETWORK_ERROR',
  ERROR_WHEN_SUBMITTING = 'ERROR_WHEN_SUBMITTING',
}

export interface InteractionsContextValue {
  interactionCounts: InteractionCounts;
  totalInteractionCounts: number;
  getInteractions: (type: FormType) => Interaction[];
  saveInteraction: (type: FormType, interaction: Interaction) => void;
  submitInteraction: (
    type: FormType,
    interaction: Interaction,
    onSubmitHandler: (response: InteractionSubmitResponse) => void
  ) => Promise<void>;
  isSubmitting: boolean;
}

const InteractionsContext = createContext<InteractionsContextValue>({
  interactionCounts: InitialInteractionCounts,
  totalInteractionCounts: 0,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  getInteractions: (type: FormType) => [],
  saveInteraction: (type: FormType, interaction: Interaction) => {},
  submitInteraction: (
    type: FormType,
    interaction: Interaction,
    onSubmitHandler: (response: InteractionSubmitResponse) => void
  ) => new Promise(r => r()),
  /* eslint-enable @typescript-eslint/no-unused-vars */
  isSubmitting: false,
});

export const useInteractions = (): InteractionsContextValue => {
  const { getUser } = useAuthContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [interactionCounts, setInteractionCounts] = useState<InteractionCounts>(
    InitialInteractionCounts
  );
  const [totalInteractionCounts, setTotalInteractionCounts] = useState(0);

  const updateCounts = (): void => {
    setInteractionCounts({
      ...getLocalCounts(),
    });
    setTotalInteractionCounts(getTotalLocalCounts());
  };

  useEffect(() => {
    updateCounts();
  }, []);

  const save = (type: FormType, interaction: Interaction): void => {
    saveLocal(type, interaction, updateCounts);
  };

  const submit = async (
    type: FormType,
    interaction: Interaction,
    onSubmitHandler: (response: any) => void
  ): Promise<void> => {
    // Side-effects: remove the submitted interaction from local storage and update the counts
    const updateLocalStorage = (): void => {
      removeLocal(type, interaction);
      updateCounts();
    };

    try {
      setIsSubmitting(true);
      await submitToApi(interaction, getUser, () => {
        updateLocalStorage();
        onSubmitHandler(InteractionSubmitResponse.SUCCESS);
      });
    } catch (err) {
      if (isNetworkError(err)) {
        updateLocalStorage();
        onSubmitHandler(InteractionSubmitResponse.NETWORK_ERROR);
      } else {
        onSubmitHandler(InteractionSubmitResponse.ERROR_WHEN_SUBMITTING);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    interactionCounts,
    totalInteractionCounts,
    getInteractions: getLocalAll,
    saveInteraction: save,
    submitInteraction: submit,
    isSubmitting,
  };
};

const InteractionsContextProvider: React.FC = ({ children }) => {
  const value = useInteractions();
  return (
    <InteractionsContext.Provider value={value}>
      {children}
    </InteractionsContext.Provider>
  );
};

export default InteractionsContextProvider;

export const useInteractionsContext = (): InteractionsContextValue =>
  useContext(InteractionsContext);

import { SiteSiteMetadata } from '../typings/graphql';
import { useSiteMetadataQuery } from '../utilities/useSiteMetadata';

const useSiteMetadata = (): SiteSiteMetadata => {
  const { site } = useSiteMetadataQuery();

  if (
    !site ||
    !site.siteMetadata ||
    !site.siteMetadata.title ||
    !site.siteMetadata.description ||
    !site.siteMetadata.author
  )
    throw Error(
      'Make sure to define "title", "description", and "author" in the `gatsby-config.js`'
    );

  const { title, description, author } = site.siteMetadata;
  return { title, description, author };
};

export default useSiteMetadata;

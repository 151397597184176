import { Typography } from '@material-ui/core';
import React from 'react';

const GoodCondition = 'No adverse condition';

export const replaceConditionsWhenGoodIsSelected = (
  conditions: string[]
): string[] => {
  return conditions.includes(GoodCondition) ? [GoodCondition] : conditions;
};

export const goodConditionIsSelected = (
  condition: string,
  selectedConditions: string[]
): boolean => {
  return (
    selectedConditions.includes(GoodCondition) && condition !== GoodCondition
  );
};

export const ConditionExplanationText: React.FC<{
  conditions: string[] | undefined;
}> = ({ conditions }) => {
  return goodConditionIsSelected('', conditions ?? []) ? (
    <Typography
      variant="body2"
      style={{ position: 'relative', marginTop: '-1em', marginBottom: '1em' }}
    >{`To select multiple conditions, you must unselect ‘No adverse condition’`}</Typography>
  ) : (
    <></>
  );
};

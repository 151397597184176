import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const SettingsIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 36.212 36.212" {...props}>
    <path d="M32.879,13.636H32.11a14.623,14.623,0,0,0-.942-2.27l.544-.544a3.334,3.334,0,0,0,0-4.715L30.106,4.5a3.334,3.334,0,0,0-4.715,0l-.545.545a14.633,14.633,0,0,0-2.27-.942V3.334A3.337,3.337,0,0,0,19.243,0H16.97a3.337,3.337,0,0,0-3.334,3.334V4.1a14.619,14.619,0,0,0-2.27.942L10.822,4.5a3.333,3.333,0,0,0-4.715,0L4.5,6.106a3.334,3.334,0,0,0,0,4.715l.545.545a14.62,14.62,0,0,0-.942,2.27H3.334A3.337,3.337,0,0,0,0,16.97v2.273a3.337,3.337,0,0,0,3.334,3.334H4.1a14.623,14.623,0,0,0,.942,2.27l-.544.544a3.334,3.334,0,0,0,0,4.715l1.606,1.606a3.334,3.334,0,0,0,4.715,0l.545-.545a14.633,14.633,0,0,0,2.27.942v.769a3.337,3.337,0,0,0,3.334,3.334h2.273a3.337,3.337,0,0,0,3.334-3.334V32.11a14.618,14.618,0,0,0,2.27-.942l.544.544a3.333,3.333,0,0,0,4.715,0l1.606-1.606a3.334,3.334,0,0,0,0-4.715l-.545-.545a14.62,14.62,0,0,0,.942-2.27h.769a3.337,3.337,0,0,0,3.334-3.334V16.97a3.338,3.338,0,0,0-3.334-3.333ZM18.106,25.985a7.879,7.879,0,1,1,7.879-7.879A7.888,7.888,0,0,1,18.106,25.985Z" />
  </SvgIcon>
);

export default SettingsIcon;

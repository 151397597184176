import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Chat: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 72.076 64.474" {...props}>
    <g transform="translate(0 -27)">
      <g transform="translate(0 27)">
        <path
          d="M67.852,51.494H58.984V31.223A4.228,4.228,0,0,0,54.761,27H17.315a4.228,4.228,0,0,0-4.223,4.223v12.67H4.223A4.228,4.228,0,0,0,0,48.116v25.9a4.228,4.228,0,0,0,4.223,4.223H16.732l5.219,5.219a1.408,1.408,0,0,0,1.991,0l2.242-2.242v.4a4.228,4.228,0,0,0,4.223,4.223H42.916l5.219,5.219a1.408,1.408,0,0,0,1.991,0l5.219-5.219H67.852a4.228,4.228,0,0,0,4.223-4.223v-25.9A4.228,4.228,0,0,0,67.852,51.494ZM15.907,31.223a1.409,1.409,0,0,1,1.408-1.408H54.761a1.409,1.409,0,0,1,1.408,1.408v25.9a1.409,1.409,0,0,1-1.408,1.408H41.669a1.408,1.408,0,0,0-1,.412l-4.635,4.636L31.4,58.945a1.408,1.408,0,0,0-1-.412H17.315a1.409,1.409,0,0,1-1.408-1.408ZM27.581,75.838l-4.636,4.636L18.31,75.838a1.408,1.408,0,0,0-1-.412H4.223a1.409,1.409,0,0,1-1.408-1.408v-25.9a1.409,1.409,0,0,1,1.408-1.408h8.869V57.125a4.228,4.228,0,0,0,4.223,4.223H29.824l5.219,5.219a1.408,1.408,0,0,0,1.991,0l5.219-5.219h.825v12.67a1.409,1.409,0,0,1-1.408,1.408H28.577A1.408,1.408,0,0,0,27.581,75.838ZM69.26,81.62a1.409,1.409,0,0,1-1.408,1.408H54.761a1.408,1.408,0,0,0-1,.412L49.13,88.075,44.494,83.44a1.408,1.408,0,0,0-1-.412H30.407A1.409,1.409,0,0,1,29,81.62V78.4l.161-.161H41.669a4.228,4.228,0,0,0,4.223-4.223V61.349h8.869a4.228,4.228,0,0,0,4.223-4.223V54.31h8.869a1.409,1.409,0,0,1,1.408,1.408Z"
          transform="translate(0 -27)"
        />
      </g>
      <g transform="translate(21.559 34.883)">
        <g transform="translate(0 0)">
          <path
            d="M180.719,83H154.556a1.408,1.408,0,0,0,0,2.815h26.163a1.408,1.408,0,1,0,0-2.815Z"
            transform="translate(-153.148 -83)"
          />
        </g>
      </g>
      <g transform="translate(47.722 42.767)">
        <g transform="translate(0 0)">
          <path
            d="M341.4,139.412a1.407,1.407,0,1,0,.412,1A1.418,1.418,0,0,0,341.4,139.412Z"
            transform="translate(-339 -139)"
          />
        </g>
      </g>
      <g transform="translate(21.559 42.767)">
        <g transform="translate(0 0)">
          <path
            d="M173.729,139H154.556a1.408,1.408,0,0,0,0,2.815h19.173a1.408,1.408,0,0,0,0-2.815Z"
            transform="translate(-153.148 -139)"
          />
        </g>
      </g>
      <g transform="translate(28.446 50.65)">
        <g transform="translate(0 0)">
          <path
            d="M222.755,195H203.479a1.408,1.408,0,0,0,0,2.815h19.276a1.408,1.408,0,1,0,0-2.815Z"
            transform="translate(-202.071 -195)"
          />
        </g>
      </g>
      <g transform="translate(21.538 50.65)">
        <g transform="translate(0 0)">
          <path
            d="M154.526,195h-.118a1.408,1.408,0,0,0,0,2.815h.118a1.408,1.408,0,0,0,0-2.815Z"
            transform="translate(-153 -195)"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default Chat;

import { useStaticQuery, graphql } from 'gatsby';
import { SiteMetadataQuery } from '../typings/graphql';

export const useSiteMetadataQuery = (): SiteMetadataQuery =>
  useStaticQuery<SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);

import {
  useReducer,
  Reducer,
  useMemo,
  ReducerState,
  Dispatch,
  ReducerAction,
} from 'react';

type ExcludeTypeField<A> = { [K in Exclude<keyof A, 'type'>]: A[K] };
type ExtractActionParameters<A, T> = ExcludeTypeField<Extract<A, { type: T }>>;

export interface FormAction {
  type: string;
}

type BaseFormActions = {
  type: 'INIT';
};

type FormActionType<F extends FormAction> = F['type'];
type FormActionPayload<F extends FormAction, K> = ExtractActionParameters<F, K>;

export type FormActionsMap<F extends FormAction, S> = {
  [C in FormActionType<BaseFormActions>]: (
    previousState: S,
    payload: FormActionPayload<F, C>
  ) => S;
} &
  {
    [K in FormActionType<F>]: (
      previousState: S,
      payload: FormActionPayload<F, K>
    ) => S;
  };

const formReducer = <F extends FormAction, S>(
  formActions: FormActionsMap<F, S>
): Reducer<S, F> => (state, action): S => {
  const { type, ...payload } = action;
  return formActions[type](state, payload);
};

const useForm = <F extends FormAction, S>(
  formActions: FormActionsMap<F, S>,
  initialState: S
): [ReducerState<Reducer<S, F>>, Dispatch<ReducerAction<Reducer<S, F>>>] => {
  const formReducerMemo = useMemo(() => formReducer<F, S>(formActions), [
    formActions,
  ]);
  return useReducer(formReducerMemo, initialState);
};

export default useForm;

import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  header: {
    marginTop: '1.3rem',
    marginBottom: '2.5rem',
  },
}));

export const FormHeader: React.FC = ({ children }) => (
  <Typography className={useStyles().header} variant="h6" component="h1">
    {children}
  </Typography>
);

export default FormHeader;

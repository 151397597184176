import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import {
  Trip,
  getTrips,
  saveTrips,
  completeTrip as completeTripService,
} from '../services/trips';
import { useAuthContext } from './authContext';

export interface TripsContextValue {
  trips: Trip[];
  tripCount: number;
  setTrips: (trips: Trip[]) => void;
  editTrip: (trip: Trip) => boolean;
  submitTrip: (trip: Trip) => void;
  completeTrip: (id: string) => () => Promise<void>;
}

const TripsContext = createContext<TripsContextValue>({
  trips: [],
  tripCount: 0,
  setTrips: () => undefined,
  editTrip: () => false,
  submitTrip: () => undefined,
  completeTrip: () => async (): Promise<void> => undefined,
});

/**
 * @private
 * @description
 * Do not use in React component
 * This hook is only exported for testing purposes
 */
export const useTrips = (): TripsContextValue & { error: string } => {
  const [error, setError] = useState('');
  const [trips, setTrips] = useState<Trip[]>([]);

  useEffect(() => {
    setTrips(getTrips());
  }, []);

  useEffect(() => {
    saveTrips(trips);
  }, [trips]);

  const editTrip = useCallback(
    (trip: Trip): boolean => {
      const oldTripIndex = trips.findIndex(({ id }) => id === trip.id);
      if (oldTripIndex < 0) return false;

      const newTrips = [...trips];
      newTrips[oldTripIndex] = { ...trip };
      setTrips(newTrips);
      return true;
    },
    [trips]
  );

  const submitTrip = useCallback(
    (trip: Trip) => {
      if (!editTrip(trip)) {
        const newTrips = [...trips, trip];
        setTrips(newTrips);
      }
    },
    [trips, editTrip]
  );

  const { getUser } = useAuthContext();

  const completeTrip = useCallback(
    (id: string) => async (): Promise<void> => {
      try {
        const updatedTrips = await completeTripService(id, getUser);
        setTrips(updatedTrips);
      } catch (error) {
        console.error(error.message);
        // TODO: Do nice loading state and snackbar errors
        setError('Something went wrong, please contact support');
      }
    },
    [getUser]
  );

  return {
    trips,
    setTrips,
    tripCount: trips.length,
    editTrip,
    submitTrip,
    completeTrip,
    error,
  };
};

const TripsContextProvider: React.FC = ({ children }) => {
  const value = useTrips();
  return (
    <TripsContext.Provider value={value}>{children}</TripsContext.Provider>
  );
};

export default TripsContextProvider;

export const useTripsContext = (): TripsContextValue =>
  useContext(TripsContext);

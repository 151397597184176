import { FormActionsMap } from '../hooks/useForm';
import {
  getInteraction,
  IndividualClient,
  Interaction,
} from '../services/interactions';
import { Tally } from '../components/tallyTracker';
import { Dispatch, useCallback, useEffect, useState } from 'react';
import useForm from '../hooks/useForm';
import { initialiseTally, defaultDimensions } from '../components/tallyTracker';
import {
  FormType,
  FormConfiguration,
  FORM_TYPE_PAGE_URL,
} from '../services/lists';
import { GetUserFunction } from '../hooks/useAuth';
import { useFormContext } from '../components/formContext';
import { navigate } from 'gatsby';
import useConfig from '../hooks/useConfig';
import { useAuthContext } from '../components/authContext';
import {
  InteractionSubmitResponse,
  useInteractionsContext,
} from '../components/interactionsContext';
import { replaceConditionsWhenGoodIsSelected } from '../services/conditions';

export class FormState extends Interaction {
  public addingIndividualClients = false;
  public editingClients = false;
  public initialised = false;
  public submittingClientEdits = false;
  public addIndividualClients?: string = undefined;
  public isApiInteraction = false;

  constructor(
    public formType: FormType,
    public dirty: boolean = false,
    public submitting: boolean = false,
    public submitted: boolean = false,
    public clientsToEdit: number[] = [],
    public error?: boolean
  ) {
    super(formType);
  }
}

function changeClients(
  previousState: FormState,
  updateFunction: (client: IndividualClient) => void
): FormState {
  const newClients = previousState.individualClients.map(client => {
    const newClient = { ...client };
    if (previousState.clientsToEdit.includes(newClient.index)) {
      updateFunction(newClient);
    }
    return newClient;
  });

  return {
    ...previousState,
    individualClients: newClients,
    dirty: true,
  };
}

export type FormActions =
  | {
      type: 'INIT';
      initialState: Partial<FormState>;
    }
  | {
      type: 'CHANGE_NAME';
      name: string;
    }
  | {
      type: 'CHANGE_CLIENT_NAME';
      name: string;
    }
  | {
      type: 'SUBMIT';
    }
  | {
      type: 'SUBMIT_CLIENT_EDITS';
    }
  | {
      type: 'SUBMIT_ERROR';
    }
  | {
      type: 'FINISH_SUBMIT';
      resetState: Partial<FormState>;
    }
  | {
      type: 'ADD_NEW_CLIENTS';
    }
  | {
      type: 'EDIT_INDIVIDUAL_CLIENT';
      clientsToEdit: number[];
    }
  | {
      type: 'EDIT_MULTIPLE_CLIENTS';
    }
  | {
      type: 'CHANGE_CLIENTS_BEING_EDITED';
      clientsToEdit: number[];
    }
  | {
      type: 'CHANGE_TALLY';
      tally: Tally;
    }
  | {
      type: 'CHANGE_ACTION';
      actions: string[];
    }
  | {
      type: 'CHANGE_REASON';
      reasons: string[];
    }
  | {
      type: 'CHANGE_OTHER_STOPS';
      otherStops: string[];
    }
  | {
      type: 'CHANGE_CLIENT_REASON';
      reasons: string[];
    }
  | {
      type: 'CHANGE_INTERACTION_OUTCOME';
      outcomes: string[];
    }
  | {
      type: 'CHANGE_TIME_OCCURRED';
      timeOccurred: string;
    }
  | {
      type: 'CHANGE_LOCATION';
      location: string;
    }
  | {
      type: 'CHANGE_REFFERED_BY';
      referredBy: string;
    }
  | {
      type: 'CHANGE_CONDITION';
      condition: string[];
    }
  | {
      type: 'CHANGE_CLIENT_CONDITION';
      condition: string[];
    }
  | {
      type: 'CHANGE_RISK';
      levelOfRisk: string;
    }
  | {
      type: 'CHANGE_COMMUNITY';
      community: string;
    }
  | {
      type: 'CHANGE_CLIENT_COMMUNITY';
      community: string;
    }
  | {
      type: 'CHANGE_DROP_LOCATION';
      dropOffLocation: string;
    }
  | {
      type: 'CHANGE_PICKUP_LOCATION';
      pickupLocation: string;
    }
  | {
      type: 'CHANGE_ACTIVITY_TYPE';
      activityType: string;
    }
  | {
      type: 'CHANGE_TRANSPORT_REQUIRED';
      transportRequired: string;
    }
  | {
      type: 'CHANGE_ADD_INDIVIDUAL_CLIENTS';
      addIndividualClients: string;
    }
  | {
      type: 'CHANGE_ACTION_TYPE';
      actionType: string;
    }
  | {
      type: 'CHANGE_SUBTYPE';
      subtype: string;
    }
  | {
      type: 'CHANGE_FOLLOW_UP';
      followUp: string;
    }
  | {
      type: 'CHANGE_CLIENT_FOLLOW_UP';
      followUp: string;
    }
  | {
      type: 'CHANGE_FOLLOW_UP_TIME';
      followUpTime: string;
    }
  | {
      type: 'CHANGE_CLIENT_FOLLOW_UP_TIME';
      followUpTime: string;
    }
  | {
      type: 'CHANGE_FOLLOW_ACTION';
      followUpActions: string[];
    }
  | {
      type: 'CHANGE_CLIENT_FOLLOW_ACTION';
      followUpActions: string[];
    }
  | {
      type: 'CHANGE_CASE_NUMBER';
      caseNumber: string;
    }
  | {
      type: 'CHANGE_CLIENT_CASE_NUMBER';
      caseNumber: string;
    }
  | {
      type: 'CHANGE_REPEAT_CUSTOMER';
      repeatCustomer: string;
    }
  | {
      type: 'CHANGE_CLIENT_REPEAT_CUSTOMER';
      repeatCustomer: string;
    }
  | {
      type: 'CHANGE_CONTACT_METHOD';
      contactMethods: string[];
    }
  | {
      type: 'CHANGE_CLIENT_ADVOCACY';
      clientAdvocacy: string;
    }
  | {
      type: 'CHANGE_HAS_GUARDIAN';
      hasGuardian: string;
    }
  | {
      type: 'CHANGE_COMMENTS';
      comments: string;
    }
  | {
      type: 'HIDE_MODAL';
    };

export const formActions: FormActionsMap<FormActions, FormState> = {
  INIT: (previousState, { initialState }) => {
    const state: FormState = {
      ...previousState,
      ...initialState,
      dirty: false,
      initialised: true,
      addingIndividualClients: false,
      editingClients: false,
      submittingClientEdits: false,
    };
    state.isApiInteraction = !!state.apiId;
    return state;
  },
  SUBMIT: previousState => {
    return { ...previousState, submitting: true };
  },
  SUBMIT_CLIENT_EDITS: previousState => {
    return { ...previousState, submittingClientEdits: true };
  },
  ADD_NEW_CLIENTS: previousState => {
    return { ...previousState, addingIndividualClients: true };
  },
  EDIT_MULTIPLE_CLIENTS: previousState => {
    return { ...previousState, editingClients: true };
  },
  EDIT_INDIVIDUAL_CLIENT: (previousState, { clientsToEdit }) => {
    return {
      ...previousState,
      clientsToEdit,
      editingClients: true,
    };
  },
  CHANGE_CLIENTS_BEING_EDITED: (previousState, { clientsToEdit }) => {
    return { ...previousState, clientsToEdit };
  },
  SUBMIT_ERROR: previousState => {
    return { ...previousState, submitting: false, error: true };
  },
  FINISH_SUBMIT: (previousState, { resetState }) => {
    return {
      ...previousState,
      ...resetState,
      submitting: false,
      submitted: true,
    };
  },
  HIDE_MODAL: previousState => {
    return {
      ...previousState,
      submitted: false,
      error: false,
    };
  },
  CHANGE_NAME: (previousState, { name }) => {
    return { ...previousState, name, dirty: true };
  },
  CHANGE_CLIENT_NAME: (previousState, { name }) => {
    return changeClients(previousState, client => (client.name = name));
  },
  CHANGE_TALLY: (previousState, { tally }) => {
    let k = 0;
    const individualClients: IndividualClient[] = [];
    for (const ageGroup of Object.keys(tally)) {
      for (const gender of Object.keys(tally[ageGroup])) {
        for (let i = 1; i <= Number(tally[ageGroup][gender]); i++) {
          individualClients[k] = { ageGroup, gender, index: k };
          k++;
        }
      }
    }
    return { ...previousState, tally, individualClients, dirty: true };
  },
  CHANGE_ACTION: (previousState, { actions }) => {
    return { ...previousState, actions, dirty: true };
  },
  CHANGE_REASON: (previousState, { reasons }) => {
    return { ...previousState, reasons, dirty: true };
  },
  CHANGE_CLIENT_REASON: (previousState, { reasons }) => {
    return changeClients(previousState, client => (client.reasons = reasons));
  },
  CHANGE_OTHER_STOPS: (previousState, { otherStops }) => {
    return { ...previousState, otherStops, dirty: true };
  },
  CHANGE_INTERACTION_OUTCOME: (previousState, { outcomes }) => {
    return { ...previousState, outcomes, dirty: true };
  },
  CHANGE_LOCATION: (previousState, { location }) => {
    return { ...previousState, location, dirty: true };
  },
  CHANGE_TIME_OCCURRED: (previousState, { timeOccurred }) => {
    return { ...previousState, timeOccurred, dirty: true };
  },
  CHANGE_REFFERED_BY: (previousState, { referredBy }) => {
    return { ...previousState, referredBy, dirty: true };
  },
  CHANGE_COMMUNITY: (previousState, { community }) => {
    return { ...previousState, community, dirty: true };
  },
  CHANGE_CLIENT_COMMUNITY: (previousState, { community }) => {
    return changeClients(
      previousState,
      client => (client.community = community)
    );
  },
  CHANGE_CONDITION: (previousState, { condition }) => {
    const conditions = replaceConditionsWhenGoodIsSelected(condition);
    return { ...previousState, condition: conditions, dirty: true };
  },
  CHANGE_CLIENT_CONDITION: (previousState, { condition }) => {
    const conditions = replaceConditionsWhenGoodIsSelected(condition);
    return changeClients(
      previousState,
      client => (client.condition = conditions)
    );
  },
  /**
   * @deprecated CHANGE_RISK is no longer being dispatched from any forms
   */
  CHANGE_RISK: (previousState, { levelOfRisk }) => {
    return { ...previousState, levelOfRisk, dirty: true };
  },
  CHANGE_DROP_LOCATION: (previousState, { dropOffLocation }) => {
    return { ...previousState, dropOffLocation, dirty: true };
  },
  CHANGE_ACTIVITY_TYPE: (previousState, { activityType }) => {
    return { ...previousState, activityType, dirty: true };
  },
  CHANGE_PICKUP_LOCATION: (previousState, { pickupLocation }) => {
    return { ...previousState, pickupLocation, dirty: true };
  },
  CHANGE_TRANSPORT_REQUIRED: (previousState, { transportRequired }) => {
    return { ...previousState, transportRequired, dirty: true };
  },
  CHANGE_ADD_INDIVIDUAL_CLIENTS: (previousState, { addIndividualClients }) => {
    return { ...previousState, addIndividualClients, dirty: true };
  },
  CHANGE_ACTION_TYPE: (previousState, { actionType }) => {
    return { ...previousState, actionType, dirty: true };
  },
  CHANGE_SUBTYPE: (previousState, { subtype }) => {
    return { ...previousState, subtype, dirty: true };
  },
  CHANGE_FOLLOW_UP: (previousState, { followUp }) => {
    return { ...previousState, followUp, dirty: true };
  },
  CHANGE_CLIENT_FOLLOW_UP: (previousState, { followUp }) => {
    return changeClients(previousState, client => (client.followUp = followUp));
  },
  CHANGE_CASE_NUMBER: (previousState, { caseNumber }) => {
    return { ...previousState, caseNumber, dirty: true };
  },
  CHANGE_CLIENT_CASE_NUMBER: (previousState, { caseNumber }) => {
    return changeClients(
      previousState,
      client => (client.caseNumber = caseNumber)
    );
  },
  CHANGE_FOLLOW_ACTION: (previousState, { followUpActions }) => {
    return { ...previousState, followUpActions, dirty: true };
  },
  CHANGE_CLIENT_FOLLOW_ACTION: (previousState, { followUpActions }) => {
    return changeClients(
      previousState,
      client => (client.followUpActions = followUpActions)
    );
  },
  CHANGE_FOLLOW_UP_TIME: (previousState, { followUpTime }) => {
    return { ...previousState, followUpTime, dirty: true };
  },
  CHANGE_CLIENT_FOLLOW_UP_TIME: (previousState, { followUpTime }) => {
    return changeClients(
      previousState,
      client => (client.followUpTime = followUpTime)
    );
  },
  CHANGE_REPEAT_CUSTOMER: (previousState, { repeatCustomer }) => {
    return { ...previousState, repeatCustomer, dirty: true };
  },
  CHANGE_CLIENT_REPEAT_CUSTOMER: (previousState, { repeatCustomer }) => {
    return changeClients(
      previousState,
      client => (client.repeatCustomer = repeatCustomer)
    );
  },
  CHANGE_CONTACT_METHOD: (previousState, { contactMethods }) => {
    return { ...previousState, contactMethods, dirty: true };
  },
  CHANGE_HAS_GUARDIAN: (previousState, { hasGuardian }) => {
    return { ...previousState, hasGuardian, dirty: true };
  },
  CHANGE_COMMENTS: (previousState, { comments }) => {
    return { ...previousState, comments, dirty: true };
  },
  CHANGE_CLIENT_ADVOCACY: (previousState, { clientAdvocacy }) => {
    return { ...previousState, clientAdvocacy, dirty: true };
  },
};

export const initialiseAction = (
  formType: FormType,
  ageGroups: string[] = [],
  existingId?: string
): FormActions => ({
  type: 'INIT',
  initialState: {
    ...new FormState(formType),
    tally: initialiseTally({
      ...defaultDimensions,
      rows: ageGroups,
    }),
    id: existingId,
  },
});

interface StartEditParams {
  formType: FormType;
  id: number;
}
export type StartEditForm = (params: StartEditParams) => Promise<void>;

const editedClients = (clients: IndividualClient[]): boolean => {
  for (const client of clients) {
    for (const key of Object.keys(client).filter(
      x => !['ageGroup', 'gender', 'apiId', 'index', 'creationTime'].includes(x)
    )) {
      const value: any = client[key as keyof IndividualClient];
      if (Array.isArray(value)) {
        if (value.length > 0) return true;
      } else if (value) {
        return true;
      }
    }
  }
  return false;
};

export const useEditForm: () => StartEditForm = () => {
  const { saveForm, clearForm } = useFormContext();
  const { getUser } = useAuthContext();
  const config = useConfig();
  const [state, dispatch] = useForm(
    formActions,
    new FormState(FormType.Transport)
  );
  const [initialised, setInitialised] = useState(false);

  const initialiseEdit = useCallback(
    async (params: StartEditParams): Promise<void> => {
      if (config.gettingConfig || !config.config) {
        return;
      }
      clearForm();
      const interaction = await getInteraction(params.id, getUser);
      dispatch({
        type: 'INIT',
        initialState: {
          ...interaction,
          tally: initialiseTally(
            {
              ...defaultDimensions,
              rows: config.config.items[params.formType].ageGroups,
            },
            interaction.tally
          ),
          addIndividualClients: editedClients(interaction.individualClients)
            ? 'Yes'
            : 'No',
          formType: params.formType,
        },
      });
      setInitialised(true);
    },
    [config.gettingConfig, getUser, dispatch]
  );

  useEffect(() => {
    saveForm(state);
    if (initialised) {
      const navigateToUrl = FORM_TYPE_PAGE_URL[state.formType];
      if (navigateToUrl) {
        navigate(navigateToUrl);
      }
    }
  }, [state, saveForm, initialised]);

  return initialiseEdit;
};

export const useNewForm = ({
  getUser,
  formType,
  config,
  existingId,
}: {
  getUser: GetUserFunction;
  formType?: FormType;
  config?: FormConfiguration;
  existingId?: string;
}): FormState & {
  dispatch: Dispatch<FormActions>;
} => {
  const { form: existingForm, saveForm, clearForm } = useFormContext();
  const {
    getInteractions,
    saveInteraction,
    submitInteraction,
  } = useInteractionsContext();
  const relevantFormType =
    formType || existingForm?.formType || FormType.Transport;
  const [state, dispatch] = useForm(
    formActions,
    new FormState(relevantFormType)
  );

  useEffect(() => {
    if (existingForm && existingForm.tally && !state.initialised) {
      console.log('Initialising form state with existing state', existingForm);
      dispatch({ type: 'INIT', initialState: existingForm });
    }
  }, [dispatch, existingForm, state]);

  useEffect(() => {
    if (config && !existingForm?.tally && !state.initialised) {
      dispatch(
        initialiseAction(relevantFormType, config.ageGroups, existingId)
      );
    }
  }, [config, dispatch, relevantFormType, existingForm, state]);

  useEffect(() => {
    const interaction = getInteractions(relevantFormType).find(
      i => i.id === state.id
    );
    if (interaction && config && !existingForm?.tally) {
      dispatch({
        type: 'INIT',
        initialState: {
          ...interaction,
        },
      });
    }
  }, [state.id, dispatch, getInteractions, config]);

  useEffect(() => {
    saveForm(state);
  }, [saveForm, state]);

  useEffect(() => {
    if (state.addingIndividualClients) {
      navigate('/edit-clients');
    }
  }, [state]);

  useEffect(() => {
    if (state.submittingClientEdits) {
      navigate('/edit-clients');
    }
  }, [state]);

  useEffect(() => {
    if (state.editingClients) {
      navigate('/edit-client');
    }
  }, [state]);

  useEffect(() => {
    if (state.submitting) {
      const {
        submitting,
        submitted,
        error,
        addingIndividualClients,
        editingClients,
        initialised,
        submittingClientEdits,
        clientsToEdit,
        dirty,
        isApiInteraction,
        ...interaction
      } = state;

      if (isApiInteraction) {
        submitInteraction(interaction.formType, interaction, response => {
          switch (response) {
            case InteractionSubmitResponse.SUCCESS:
            case InteractionSubmitResponse.NETWORK_ERROR:
              navigate('/activity-log');
              break;
            default:
              console.error(error);
              dispatch({
                type: 'SUBMIT_ERROR',
              });
          }
        });
      } else {
        saveInteraction(interaction.formType, interaction);
        navigate(`/active-interactions#${interaction.formType}`);
      }

      return (): void => {
        if (state.submitting) {
          clearForm();
        }
      };
    }
  }, [
    state.submitting,
    state,
    config,
    relevantFormType,
    getUser,
    dispatch,
    clearForm,
  ]);

  useEffect(() => {
    let timeout: number;
    if (state.submitted || state.error) {
      timeout = window.setTimeout(
        () =>
          dispatch({
            type: 'HIDE_MODAL',
          }),
        3000
      );
    }

    return (): void => window.clearTimeout(timeout);
  }, [state.submitted, state.error, dispatch]);

  return { ...state, dispatch };
};

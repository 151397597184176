import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Offline } from '../icons';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const OfflineIndicator: React.FC<{}> = () => {
  const classNames = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const close = (): void => {
    setOpenModal(false);
  };

  return (
    <>
      <Offline onClick={(): void => setOpenModal(true)} />
      <Dialog open={openModal} onClose={close}>
        <DialogTitle>
          <Typography variant="h6" component="h2">
            Offline mode
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You are currently working in offline mode, you can continue to work
            and your information will be submitted once you gain connectivity.
          </Typography>
          <Typography variant="body1">
            Editing synced interactions is disabled.
          </Typography>

          <Button
            color="secondary"
            variant="contained"
            fullWidth
            className={classNames.closeButton}
            onClick={close}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OfflineIndicator;

import { FluidObject } from 'gatsby-image';
import { useCommunitiesLogoQuery } from '../utilities/useCommunitiesLogoQuery';

const useCommunitiesLogo = (): FluidObject => {
  const data = useCommunitiesLogoQuery();

  if (
    !data ||
    !data.file ||
    !data.file.childImageSharp ||
    !data.file.childImageSharp.fluid
  ) {
    throw Error('Could not retrieve the Communities Logo');
  }
  const fluid = data.file.childImageSharp.fluid;
  const result: FluidObject = {
    aspectRatio: fluid.aspectRatio as number,
    src: fluid.src as string,
    srcSet: fluid.srcSet as string,
    sizes: fluid.sizes as string,
    tracedSVG: fluid.tracedSVG as string,
  };

  return result;
};

export default useCommunitiesLogo;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Logout: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 512.062 512.062" {...props}>
    <path d="m104.13 450.102c-4.992 0-6.785-3.619-8.912-7.249-2.231-3.813-.95-8.714 2.862-10.946 3.814-2.232 8.715-.949 10.946 2.862.711 1.215 1.294 2.175 1.836 3.023 3.431 5.367-.503 12.31-6.732 12.31z" />
    <path d="m244.352 512.031h-28.35c-36.659 0-71.323-14.079-97.608-39.646-3.167-3.08-3.237-8.146-.156-11.313 3.079-3.166 8.144-3.238 11.313-.156 23.28 22.644 53.983 35.114 86.452 35.114h28.35c36.296 0 70.421-14.12 96.102-39.765l149.075-157.071c14.089-14.952 3.051-35.163-13.808-35.163-20.788 0-40.335 8.05-55.08 22.675l-43.218 51.177c-12.905 15.346-41.422 6.396-41.422-16.481v-42.53c0-1.639-3.47 11.896 52.692-197.69 4.748-17.737-8.651-35.249-27.063-35.249-11.625 0-23.132 7.266-27.069 20.894l-54.87 191.41c-2.542 8.874-15.69 7.014-15.69-2.204v-212c0-25.033-30.308-37.272-47.808-19.799-5.28 5.288-8.192 12.322-8.192 19.799v205.8c0 9.313-13.322 11.052-15.728 2.07l-47.17-176.06c-1.938-7.222-6.571-13.259-13.049-16.999-18.745-10.819-42.014 2.894-42.014 24.219 0 5.241-3.701-10.14 49.348 187.829 2.41 8.995-10.006 14.123-14.655 6.07l-63.941-110.75c-12.093-20.93-44.791-12.673-44.791 12.04 0 4.178 1.112 8.313 3.218 11.96l44.9 77.771c18.24 31.6 27.882 67.579 27.882 104.05 0 11.963 1.7 23.795 5.054 35.167 1.249 4.238-1.173 8.687-5.411 9.937-4.231 1.253-8.686-1.173-9.937-5.411-3.786-12.842-5.706-26.196-5.706-39.692 0-33.667-8.9-66.881-25.738-96.051l-44.9-77.77c-11.224-19.445-4.115-43.801 14.647-54.638 19.089-11.03 43.603-4.466 54.638 14.636l33.558 58.124-32.652-121.854c-6.35-23.596 7.806-47.658 31.122-53.889 23.472-6.298 47.583 7.618 53.882 31.106l31.443 117.359v-145.026c0-24.142 19.511-44 44-44 24.262 0 44 19.738 44 44v155.061l39.162-136.613c5.169-19.166 22.621-32.547 42.468-32.547 28.987 0 49.958 27.593 42.518 55.388l-52.147 194.604v41.478c0 7.067 8.427 10.594 13.447 5.863 30.107-35.651 43.272-51.264 43.646-51.641 17.794-17.793 41.456-27.593 66.627-27.593 31.553 0 49.063 38.4 25.346 62.248-154.128 162.394-149.092 157.09-149.23 157.23-28.711 28.711-66.885 44.522-107.487 44.522z" />
    <path d="m216.002 463.031c-4.418 0-8-3.582-8-8 0-63.903 47.695-118.445 110.943-126.87 4.394-.576 8.404 2.494 8.986 6.873.584 4.38-2.494 8.403-6.873 8.986-55.331 7.37-97.057 55.095-97.057 111.011.001 4.418-3.581 8-7.999 8z" />
  </SvgIcon>
);

export default Logout;

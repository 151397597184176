import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ChildrenFamilySupport: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 128 128" {...props}>
    <path d="M25 27c-7.17 0-13-5.83-13-13S17.83 1 25 1s13 5.83 13 13-5.83 13-13 13zm0-20c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm81 70c-5.51 0-10-4.49-10-10s4.49-10 10-10 10 4.49 10 10-4.49 10-10 10zm0-14c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zM64 27c-7.17 0-13-5.83-13-13S56.83 1 64 1s13 5.83 13 13-5.83 13-13 13zm0-20c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7z"></path>
    <path d="m125.95 107.46-1.76-9.67c-1.58-8.67-8.52-15.22-17.27-16.28-10.71-1.3-18.97-9.7-20.08-20.43l-1.09-10.43A21.878 21.878 0 0 0 63.93 31c-4.66 0-9.11 1.44-12.87 4.17a3.004 3.004 0 0 0-.67 4.19 3.004 3.004 0 0 0 4.19.67A15.82 15.82 0 0 1 63.93 37c8.19 0 15 6.14 15.85 14.28l1.09 10.43c.9 8.62 5.46 16.03 12.13 20.71V98c0 3.63 1.5 6.91 3.91 9.27l1.1 16.91c.1 1.59 1.42 2.81 2.99 2.81.06 0 .13 0 .19-.01 1.65-.1 2.91-1.53 2.81-3.18l-.82-13.13c.91.2 1.86.32 2.83.32h2.81l-.8 12.81a3 3 0 0 0 2.81 3.18c.06 0 .13.01.19.01 1.57 0 2.89-1.22 2.99-2.81l1-16c.05-.83-.24-1.64-.81-2.24s-1.37-.94-2.2-.94h-6c-3.86 0-7-3.14-7-7V85.66c2.27.89 4.68 1.51 7.2 1.81 6.13.74 10.99 5.32 12.09 11.39l1.76 9.67a2.997 2.997 0 0 0 3.49 2.41c1.63-.28 2.71-1.85 2.41-3.48z"></path>
    <path d="M77 75c-1.66 0-3 1.34-3 3v17H54c0-1.66-1.34-3-3-3s-3 1.34-3 3c0 3.31 2.69 6 6 6h.22l1.79 23.23A2.999 2.999 0 0 0 59 127c.08 0 .15 0 .23-.01a3 3 0 0 0 2.76-3.22L60.24 101h7.52l-1.75 22.77a3 3 0 0 0 2.76 3.22c.08.01.16.01.23.01 1.55 0 2.87-1.2 2.99-2.77L73.78 101H74c3.31 0 6-2.69 6-6V78c0-1.66-1.34-3-3-3zM47.75 50.66A21.879 21.879 0 0 0 25.93 31C13.84 31 4 40.84 4 52.94V82c0 3.91 2.51 7.25 6 8.49V118c0 4.96 4.04 9 9 9 2.31 0 4.41-.88 6-2.31a8.944 8.944 0 0 0 6 2.31c4.96 0 9-4.04 9-9V92c0-1.66-1.34-3-3-3s-3 1.34-3 3v26c0 1.65-1.35 3-3 3s-3-1.35-3-3V82c0-1.66-1.34-3-3-3s-3 1.34-3 3v36c0 1.65-1.35 3-3 3s-3-1.35-3-3V88c0-1.66-1.34-3-3-3-1.65 0-3-1.35-3-3V52.94C10 44.15 17.15 37 25.93 37c8.14 0 14.92 6.07 15.83 14.14V82c0 1.66 1.34 3 3 3s3-1.34 3-3V50.97c.01-.1 0-.2-.01-.31z"></path>
  </SvgIcon>
);

export default ChildrenFamilySupport;
